import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
  createStudySiteStudiesStudyIdSitesPost,
  deleteStudySitesStudiesStudyIdSitesDelete,
  deleteStudySiteStudiesStudyIdSitesSiteIdDelete,
  updateStudySiteStudiesStudyIdSitesSiteIdPut,
} from '@common/config/api/client/services.gen'
import { AxiosError } from 'axios'
import { CreateOrUpdateStudySiteSchema, HTTPValidationError, StudySiteSchema } from '@common/config/api/client'

export const useDeleteStudySite = () => {
  return useMutation({
    mutationFn: ({ studyId, siteId }: { studyId: string; siteId: string }) =>
      deleteStudySiteStudiesStudyIdSitesSiteIdDelete({ path: { study_id: studyId, site_id: siteId } }),
  })
}

export const useBulkDeleteStudySites = () => {
  return useMutation({
    mutationFn: ({ studyId, siteIds }: { studyId: string; siteIds: Array<string> }) =>
      deleteStudySitesStudiesStudyIdSitesDelete({
        query: {
          site_ids: siteIds.join(','),
        },
        path: {
          study_id: studyId,
        },
      }),
  })
}

export const useCreateStudySite = (): UseMutationResult<
  StudySiteSchema,
  AxiosError<HTTPValidationError>,
  { studyId: string; sitePayload: CreateOrUpdateStudySiteSchema }
> => {
  return useMutation({
    mutationFn: async ({ studyId, sitePayload }) => {
      const response = await createStudySiteStudiesStudyIdSitesPost({
        body: sitePayload,
        throwOnError: true,
        path: {
          study_id: studyId,
        },
      })
      return response.data
    },
  })
}

export const useUpdateStudySite = (): UseMutationResult<
  StudySiteSchema,
  AxiosError<HTTPValidationError>,
  { studyId: string; siteId: string; sitePayload: CreateOrUpdateStudySiteSchema }
> => {
  return useMutation({
    mutationFn: async ({ studyId, siteId, sitePayload }) => {
      const response = await updateStudySiteStudiesStudyIdSitesSiteIdPut({
        path: { study_id: studyId, site_id: siteId },
        body: sitePayload,
        throwOnError: true,
      })
      return response.data
    },
  })
}
