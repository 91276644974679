import {
  getAllSubjectsTimepointsStudiesStudyIdSitesSiteIdSubjectsGet,
  GetAllSubjectsTimepointsStudiesStudyIdSitesSiteIdSubjectsGetData,
} from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'

export const useGetSubjectsList = (
  queryKey: string[],
  params: GetAllSubjectsTimepointsStudiesStudyIdSitesSiteIdSubjectsGetData['path'],
) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getAllSubjectsTimepointsStudiesStudyIdSitesSiteIdSubjectsGet({
        path: params,
      }),
  })
}
