import {
  HTTPValidationError,
  UpdateQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdPutResponse,
  UpdateQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdPutData,
  updateQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdPut,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useUpdateQcStudyDefinitionMutation = (): UseMutationResult<
  UpdateQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdPutResponse,
  AxiosError<HTTPValidationError>,
  UpdateQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdPutData
> => {
  return useMutation<
    UpdateQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdPutResponse,
    AxiosError<HTTPValidationError>,
    UpdateQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdPutData
  >({
    mutationFn: async (query) => {
      const response =
        await updateQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdPut({
          path: query.path,
          body: query.body,
          throwOnError: true,
        })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTask'] })
    },
  })
}
