import { Box, Tab, Tabs } from '@mui/material'
import { ReactElement, SyntheticEvent } from 'react'

type SegmentedControlsProps = {
  controls: {
    icon: ReactElement
  }[]
  value: number
  setValue: (val: number) => void
}

const SegmentedControls = ({ controls, value, setValue }: SegmentedControlsProps) => {
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Tabs
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        sx={{
          bgcolor: 'transparent',
          minHeight: 'unset',
        }}
        value={value}
        onChange={handleChange}
        aria-label="enhanced tabs"
      >
        {controls.map((control, index) => (
          <Tab
            key={index}
            icon={control.icon}
            sx={{
              maxWidth: 36,
              minWidth: 36,
              minHeight: 36,
              maxHeight: 36,
              color: 'text.secondary',
              marginX: 0.25,
              '&.Mui-selected': {
                bgcolor: 'background.default',
                color: 'text.primary',
                boxShadow: 2,
                borderRadius: 2,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default SegmentedControls
