type NestedKeyOf<T> = T extends object
  ? { [K in keyof T]: K extends string ? (T[K] extends object ? K | `${K}.${NestedKeyOf<T[K]>}` : K) : never }[keyof T]
  : never

export const groupBy = <T extends object>(array: T[], path: NestedKeyOf<T>): Record<string, T[]> => {
  return array.reduce((groups: Record<string, T[]>, item) => {
    const value = path.split('.').reduce((obj, key) => {
      return obj && typeof obj === 'object' ? (obj as Record<string, unknown>)[key] : undefined
    }, item as unknown)

    const key = String(value ?? '')

    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(item)
    return groups
  }, {})
}
