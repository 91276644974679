import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

interface Option {
  value: string
  label: string
}

interface SelectFieldProps<T extends FieldValues, P extends Path<T>> {
  name: P
  control: Control<T>
  label: string
  options: Option[]
  defaultValue?: PathValue<T, P>
}

export function SelectField<T extends FieldValues, P extends Path<T>>({
  name,
  control,
  label,
  options,
  defaultValue,
}: SelectFieldProps<T, P>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const multiple = Array.isArray(field.value)

        const currentValue = multiple
          ? options.filter((opt) => (field.value as string[]).includes(opt.value))
          : options.find((opt) => opt.value === (field.value as string)) || null

        return (
          <FormControl fullWidth error={!!fieldState.error}>
            <Autocomplete
              multiple={multiple}
              options={options}
              value={currentValue}
              onChange={(_, newValue) => {
                if (multiple) {
                  field.onChange((newValue as Option[]).map((opt) => opt.value))
                } else {
                  field.onChange((newValue as Option | null)?.value ?? '')
                }
              }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        )
      }}
    />
  )
}
