import { Stack } from '@mui/material'

import { SPACING } from '@common/theme/spacing'
import SitesTable from './SitesTable'
import SitesTableSkeleton from './SitesTableSkeleton'
import { useGetStudySites } from '../hooks/useStudySitesQueries'
import useStudySitesTableHandlers from '../hooks/useSitesTableHandlers'
import { SiteAssociationTypeEnum } from '../types'
import CreateSiteDialog from './CreateSiteDialog'
import UpdateSiteDialog from './UpdateSiteDialog'

export default function Sites() {
  const {
    page,
    isSiteDialogVisible,
    editingSiteId,
    sitesParams,
    editingSite,
    handleSaveSite,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    handleSaveEditedSite,
    handleDeleteSite,
    setEditingSiteId,
    setIsSiteDialogVisible,
    handleBulkDeleteSites,
    isEditingSitePending,
  } = useStudySitesTableHandlers()

  const { data: sites, isPending: isSitesPending } = useGetStudySites(sitesParams)

  const isLoading = isSitesPending

  const shouldShowSiteDialog = isSiteDialogVisible && (!editingSiteId || !isEditingSitePending)

  return (
    <>
      <Stack spacing={SPACING.sectionsSpacing}>
        {isLoading ? (
          <SitesTableSkeleton />
        ) : (
          <SitesTable
            sites={sites}
            handlePageChange={(event, newPage) => handlePageChange(sites, event, newPage)}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSearch={handleSearch}
            searchValue={sitesParams.search ?? ''}
            page={page}
            orderBy={sitesParams.order_by_field}
            order={sitesParams.asc_order ? 'asc' : 'desc'}
            onRequestSort={handleRequestSort}
            onAddClick={handleAddClick}
            onEditClick={handleEditClick}
            onDeleteSite={handleDeleteSite}
            onBulkDeleteSites={handleBulkDeleteSites}
          />
        )}
      </Stack>
      {shouldShowSiteDialog &&
        (editingSiteId ? (
          <UpdateSiteDialog
            title="Edit Site"
            onSave={handleSaveEditedSite}
            onClose={() => {
              setIsSiteDialogVisible(false)
              setEditingSiteId(null)
            }}
            data={editingSite}
          />
        ) : (
          <CreateSiteDialog
            onClose={() => {
              setIsSiteDialogVisible(false)
              setEditingSiteId(null)
            }}
            title="Add new site"
            onSave={handleSaveSite}
            data={{
              name: '',
              pseudo_id: '',
              contact_email: '',
              contact_phone: '',
              site_associations: [],
              site_association_type: SiteAssociationTypeEnum.PRIMARY,
              modalities: [],
            }}
          />
        ))}
    </>
  )
}
