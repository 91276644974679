import { Paper, Stack } from '@mui/material'
import { InputField } from './InputField'
import { SelectField } from './SelectField'
import { Control } from 'react-hook-form'
import { QcStudyDefinitionCreateFormValues } from './StudyAndSeriesDefinitionValidationSchema'
import { QcTimepointContainerSubmission } from '@common/config/api/client'

export default function StudyDefinitionForm({
  control,
  qcTimepointContainerSubmissions,
}: {
  control: Control<QcStudyDefinitionCreateFormValues>
  qcTimepointContainerSubmissions: QcTimepointContainerSubmission[]
}) {
  const sourceStudyDescriptions = qcTimepointContainerSubmissions
    .map((qcTimepointContainerSubmission) =>
      qcTimepointContainerSubmission.exam_metadata.map((examMetadata) => ({
        value: examMetadata.exam_metadata_id,
        label: examMetadata.study_description || '',
      })),
    )
    .flat()

  return (
    <Paper
      sx={{
        padding: 2,
        borderRadius: 4,
        bgcolor: 'paper.main',
      }}
    >
      <Stack spacing={2}>
        <InputField control={control} name="study_description" label="New study description" />
        <SelectField
          control={control}
          name="exam_metadata_ids"
          label="Source study description"
          options={sourceStudyDescriptions}
        />
      </Stack>
    </Paper>
  )
}
