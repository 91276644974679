import { Button, Stack } from '@mui/material'
import StudyDefinitionForm from './StudyDefinitionForm'
import SeriesDefinitionFormList from './SeriesDefinitionFormList'
import { Control } from 'react-hook-form'
import { QcStudyDefinitionCreateFormValues } from './StudyAndSeriesDefinitionValidationSchema'
import { QcTimepointContainerSubmission } from '@common/config/api/client'

export default function StudyAndSeriesDefinitionForm({
  control,
  qcTimepointContainerSubmissions,
  saveDisabled,
}: {
  control: Control<QcStudyDefinitionCreateFormValues>
  qcTimepointContainerSubmissions: QcTimepointContainerSubmission[]
  saveDisabled: boolean
}) {
  return (
    <Stack spacing={2}>
      <StudyDefinitionForm control={control} qcTimepointContainerSubmissions={qcTimepointContainerSubmissions} />
      <SeriesDefinitionFormList control={control} qcTimepointContainerSubmissions={qcTimepointContainerSubmissions} />
      <Stack direction="row" alignItems="center" justifyContent="end">
        <Button
          variant="contained"
          sx={{
            color: 'text.primary',
            py: 0.5,
            px: 1.5,
          }}
          color="secondary"
          type="submit"
          disabled={saveDisabled}
        >
          Save changes
        </Button>
      </Stack>
    </Stack>
  )
}
