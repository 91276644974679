import BaseDialog from '@common/components/BaseDialog'
import { TextField } from '@common/components/Form/TextField'
import { zodResolver } from '@hookform/resolvers/zod'
import { alpha, Stack, Typography, useTheme } from '@mui/material'
import { useForm } from 'react-hook-form'
import { SiteCreateSchema } from '@features/study_details/sites/schemas/sitesValidationSchema'
import RadioGroup from '@common/components/Form/RadioGroup'
import { SPACING } from '@common/theme/spacing'
import { Dropdown } from '@common/components/Form/Dropdown'
import { useGetStudySitesMetadata } from '../hooks/useStudySitesQueries'
import { useParams } from 'react-router-dom'
import { SiteAssociationTypeEnum } from '../types'
import { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person'

export type SiteFormValues = {
  pseudo_id: string
  name: string
  contact_email: string
  contact_phone: string | null
  site_association_type: SiteAssociationTypeEnum
  modalities: Array<string>
  site_associations: Array<string>
}

export interface CreateSiteDialogProps {
  onSave: (data: SiteFormValues) => void
  onClose: () => void
  data?: SiteFormValues | null
  title: string
}

const BULK_UPLOAD_SITES = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]
const SITE_ASSOCIATION_TYPE = [
  {
    label: 'Primary',
    value: SiteAssociationTypeEnum.PRIMARY,
  },
  {
    label: 'Secondary',
    value: SiteAssociationTypeEnum.SECONDARY,
  },
]

const CreateSiteDialog = ({ onSave, onClose, data, title }: CreateSiteDialogProps) => {
  const [showForm, setShowForm] = useState(false)
  const theme = useTheme()

  const siteCreationModeForm = useForm<{ bulk_upload_sites: boolean }>({
    mode: 'onSubmit',
  })
  const singleSiteForm = useForm<SiteFormValues>({
    resolver: zodResolver(SiteCreateSchema),
    mode: 'onBlur',
    defaultValues: {
      name: data?.name || '',
      pseudo_id: data?.pseudo_id,
      site_association_type: data?.site_association_type ?? SiteAssociationTypeEnum.PRIMARY,
      contact_email: '',
      contact_phone: null,
      modalities: [],
    },
  })

  const bulkUploadSites = siteCreationModeForm.watch('bulk_upload_sites')
  const siteAssociationType = singleSiteForm.watch('site_association_type')
  const siteAssociations = singleSiteForm.watch('site_associations')
  const { studyId } = useParams<{ studyId: string }>()
  const { data: studySitesMetadata, isLoading } = useGetStudySitesMetadata(studyId!)

  const getOppositeAssociationTypeData = (type: SiteAssociationTypeEnum) => {
    return type === SiteAssociationTypeEnum.PRIMARY
      ? {
          value: SiteAssociationTypeEnum.SECONDARY,
          label: 'Secondary',
        }
      : {
          value: SiteAssociationTypeEnum.PRIMARY,
          label: 'Primary',
        }
  }

  const getAvailableSiteAssociations = () => {
    if (!studySitesMetadata?.site_associations) return []
    const oppositeType = getOppositeAssociationTypeData(siteAssociationType as SiteAssociationTypeEnum).value
    return studySitesMetadata.site_associations[oppositeType] || []
  }

  const isBulkUploadSitesTruthy = () => bulkUploadSites !== undefined && bulkUploadSites !== null

  const handleFormSubmit = async () => {
    const formData = singleSiteForm.getValues()

    if (!showForm) {
      if (isBulkUploadSitesTruthy()) {
        setShowForm(true)
      }
    } else {
      const isValid = await singleSiteForm.trigger()
      if (isValid) {
        onSave(formData)
        singleSiteForm.reset()
        onClose()
      }
    }
  }

  const renderSingleSiteForm = () => (
    <Stack spacing={3}>
      <Typography variant="body1" color="text.primary">
        1. General information
      </Typography>
      <TextField name="pseudo_id" control={singleSiteForm.control} label="Site ID" />
      <Stack direction="row" gap={SPACING.formFieldsSpacing}>
        <TextField name="name" control={singleSiteForm.control} label="Name" />
        <TextField name="contact_phone" control={singleSiteForm.control} label="Phone number" />
      </Stack>
      <TextField name="contact_email" control={singleSiteForm.control} label="Email address" />
      <Typography variant="body1" color="text.primary">
        2. Company information
      </Typography>
      <Dropdown
        name="modalities"
        control={singleSiteForm.control}
        options={studySitesMetadata?.modalities ?? []}
        loading={isLoading}
        label="Modalities"
        multiple
      />
      <Typography variant="body1" color="text.primary">
        3. Site relationships
      </Typography>
      <Stack direction="row" gap={3} alignItems="center">
        <Typography>Is this a primary or secondary site?</Typography>
        <RadioGroup name="site_association_type" control={singleSiteForm.control} options={SITE_ASSOCIATION_TYPE} row />
      </Stack>

      <Dropdown
        name="site_associations"
        control={singleSiteForm.control}
        options={getAvailableSiteAssociations()}
        label={`Select ${getOppositeAssociationTypeData(siteAssociationType as SiteAssociationTypeEnum).label} site associations`}
        multiple
        getOptionDisabled={() => (siteAssociations?.length > 0 ? true : false)}
      />
    </Stack>
  )

  const renderBulkUploadForm = () => {
    return (
      <Stack spacing={3}>
        <Stack
          px={2}
          py={4}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 1,
            textAlign: 'center',
            bgcolor: alpha(theme.palette.primary.main, 0.08),
            border: `1px dashed ${theme.palette.primary.main}`,
          }}
        >
          <PersonIcon sx={{ color: theme.palette.primary.main }} />
          <Typography variant="body2" mb={0.5} mt={1}>
            <Typography
              variant="subtitle2"
              component="span"
              sx={{ color: 'primary.main', textDecoration: 'underline' }}
            >
              Click to upload
            </Typography>
            &nbsp;or drag and drop
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary">
            .csv, .xlsx (max. 3MB)
          </Typography>
        </Stack>
      </Stack>
    )
  }

  return (
    <BaseDialog
      title={title}
      description="Please provide the details for this study site below"
      open={true}
      onClose={onClose}
      onSubmit={handleFormSubmit}
      form={singleSiteForm}
      width={600}
      submitLabel={showForm ? (bulkUploadSites ? 'Add Sites' : 'Create new site') : 'Create Site'}
      cancelLabel={'Cancel'}
      scroll="paper"
    >
      <Stack spacing={4}>
        <Stack direction="row" gap={3} alignItems="center">
          <Typography>Do you want to bulk upload sites?</Typography>
          <RadioGroup name="bulk_upload_sites" control={siteCreationModeForm.control} options={BULK_UPLOAD_SITES} row />
        </Stack>

        {showForm && <>{bulkUploadSites ? renderBulkUploadForm() : renderSingleSiteForm()}</>}
      </Stack>
    </BaseDialog>
  )
}

export default CreateSiteDialog
