import { TabConfig } from '@common/components/Tab/tabs.types'
import QcTaskListTableCompletedTasks from './pages/QcTaskListTableCompletedTasks'
import QcTaskListTableMyTasks from './pages/QcTaskListTableMyTasks'
import QcTaskListTableTaskManager from './pages/QcTaskListTableTaskManager'
import QcTaskListTableTaskPool from './pages/QcTaskListTableTaskPool'
import { QCTaskPermission } from '@auth/permissionsEnum'

export enum QCTaskListTabsKeyEnum {
  MY_TASKS = 'MY_TASKS',
  TASK_POOL = 'TASK_POOL',
  COMPLETE_TASKS = 'COMPLETE_TASKS',
  TASK_MANAGER = 'TASK_MANAGER',
}

export type QCTaskListTabsKey = QCTaskListTabsKeyEnum

export const QC_TASK_LIST_TABS: Record<QCTaskListTabsKey, TabConfig> = {
  MY_TASKS: {
    label: 'My tasks',
    content: <QcTaskListTableMyTasks />,
    permission: QCTaskPermission.VIEW_ASSIGNED_TASKS,
  },
  TASK_POOL: {
    label: 'Task pool',
    content: <QcTaskListTableTaskPool />,
    permission: QCTaskPermission.VIEW_TASK_POOL,
  },
  COMPLETE_TASKS: {
    label: 'Complete',
    content: <QcTaskListTableCompletedTasks />,
    permission: QCTaskPermission.VIEW_COMPLETED_TASKS,
  },
  TASK_MANAGER: {
    label: 'Task manager',
    content: <QcTaskListTableTaskManager />,
    permission: QCTaskPermission.MANAGE_TASKS,
  },
}

export const UNSELECATBLE_TASK_STATUS = ['COMPLETE', 'EXCLUDED']
