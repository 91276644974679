import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { ResponseCriteriaSchema } from '@common/config/api/client'

interface Props {
  open: boolean
  onClose: () => void
  responseCriteria: ResponseCriteriaSchema[]
  onSelect: (criteria: ResponseCriteriaSchema) => void
}

export default function ResponseCriteriaDialog({ open, onClose, responseCriteria, onSelect }: Readonly<Props>) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth aria-labelledby="response-criteria-dialog-title">
      <DialogTitle id="response-criteria-dialog-title">Choose response criteria</DialogTitle>
      <DialogContent>
        <List aria-label="Response criteria list">
          {responseCriteria.map((criteria) => (
            <ListItem key={criteria.id} disablePadding>
              <ListItemButton onClick={() => onSelect(criteria)} aria-label={`Select ${criteria.name}`}>
                <ListItemText primary={criteria.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}
