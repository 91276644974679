import { Route, Routes, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { NotFound } from '@common/components/NotFound/NotFound'
import { ROUTES } from './routes'
import { Toaster } from 'sonner'
import setAxiosConfig from '@common/config/api/setAxiosConfig'
import CreateOrUpdateStudyLayout from '@study_setup/components/layouts/CreateOrUpdateStudyLayout'
import CreateOrUpdateStudy from '@study_setup/components/CreateOrUpdateStudy'
import ReaderTaskListTabs from './features/reader_task_list_view/pages/ReaderTaskListTabs'
import SubjectDetails from './features/subject/components/SubjectDetails'
import { FormManagement } from './features/form_management/components'
import StudyDetailsLayout from '@study_setup/components/layouts/StudyDetailsLayout'
import QcTaskListTabs from '@qc_task_list_view/pages/QcTaskListTabs'
import { BaseLayout } from '@common/components/layouts/BaseLayout'
import StudyDetails from '@features/study_details/components/StudyDetails'
import Queries from '@features/study_details/components/StudyDetails/Queries'
import AccountManagement from '@features/study_details/components/StudyDetails/AccountManagement'
import { ProtectedRoute } from '@auth/components/ProtectedRoute'
import {
  FormManagementPermission,
  SeriesLabelManagementPermission,
  StudyPermission,
  CompanyManagementPermission,
  StudyUserManagementPermission,
} from '@auth/permissionsEnum'
import Forbidden from '@auth/components/Forbidden'
import SubjectListTable from '@features/subject/pages/SubjectListTable'
import Home from '@features/study_view/pages'
import SeriesLabelManagement from '@features/series_label_management/components/SeriesLabelManagement'
import QCDetailsView from '@features/qc_task_details_view/pages/QCDetailsView'
import { UserManagement, UserDetails } from '@features/user_management'
import Sites from '@features/study_details/sites/components'
import AccountManagementProfile from '@features/study_details/components/StudyDetails/AccountManagement/AccountManagementProfile'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.feedbackIntegration({
      isNameRequired: true,
      isEmailRequired: true,
      colorScheme: 'system',
      triggerLabel: 'Report an issue',
      formTitle: 'Report an issue',
      messagePlaceholder: 'What is the issue? What did you expect?',
      submitButtonLabel: 'Send Issue Report',
      successMessageText: "Thank you for your feedback! We'll review it as soon as possible.",
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

setAxiosConfig()

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/uppySw.js')
    .then((registration) => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope)
    })
    .catch((error) => {
      console.log(`Registration failed with ${error}`)
    })
}

export default function App() {
  return (
    <>
      <Toaster position="top-right" />
      <SentryRoutes>
        <Route element={<BaseLayout />}>
          <Route path={ROUTES.HOME.path} element={<Home />} />
          <Route path={ROUTES.NOT_FOUND.path} element={<NotFound />} />
          <Route path={ROUTES.FORBIDDEN.path} element={<Forbidden />} />
          <Route
            path={ROUTES.USERS_MANAGEMENT.path}
            element={
              <ProtectedRoute permissions={[CompanyManagementPermission.ALL]}>
                <UserManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.USER_PROFILE.path}
            element={
              <ProtectedRoute permissions={[CompanyManagementPermission.ALL]}>
                <UserDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.FORM_MANAGEMENT.path}
            element={
              <ProtectedRoute permissions={[FormManagementPermission.ALL]}>
                <FormManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.SERIES_LABEL_MANAGEMENT.path}
            element={
              <ProtectedRoute permissions={[SeriesLabelManagementPermission.ALL]}>
                <SeriesLabelManagement />
              </ProtectedRoute>
            }
          />
          <Route path={ROUTES.SUBJECT_DETAILS.path} element={<SubjectDetails />} />
          <Route path={ROUTES.QC_TASK_DETAILS.path} element={<QCDetailsView />} />
        </Route>
        <Route element={<StudyDetailsLayout />}>
          <Route
            path={ROUTES.STUDY_DETAILS.path}
            element={
              <ProtectedRoute permissions={[]}>
                <StudyDetails />
              </ProtectedRoute>
            }
          />
          <Route path={ROUTES.SITES.path} element={<Sites />} />
          <Route path={ROUTES.SUBJECTS.path} element={<SubjectListTable />} />
          <Route path={ROUTES.READER_TASKS.path} element={<ReaderTaskListTabs />} />
          <Route path={ROUTES.QC_TASKS.path} element={<QcTaskListTabs />} />
          <Route path={ROUTES.QUERIES.path} element={<Queries />} />
          <Route
            path={ROUTES.ACCOUNT_MANAGEMENT.path}
            element={
              <ProtectedRoute permissions={[StudyUserManagementPermission.ALL]}>
                <AccountManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ACCOUNT_MANAGEMENT_PROFILE.path}
            element={
              <ProtectedRoute permissions={[StudyUserManagementPermission.ALL]}>
                <AccountManagementProfile />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route element={<CreateOrUpdateStudyLayout />}>
          <Route
            path={ROUTES.CREATE_STUDY.path}
            element={
              <ProtectedRoute permissions={[StudyPermission.ALL]}>
                <CreateOrUpdateStudy />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.UPDATE_STUDY.path}
            element={
              <ProtectedRoute permissions={[StudyPermission.ALL]}>
                <CreateOrUpdateStudy />
              </ProtectedRoute>
            }
          />
        </Route>
      </SentryRoutes>
    </>
  )
}
