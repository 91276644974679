import { Breadcrumb, BreadcrumbItem } from '@common/components/Breadcrumb'
import { Box, Stack, Typography } from '@mui/material'
import { RouteKeyEnum } from '../../../../routes.tsx'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import Grid from '@mui/material/Grid2'
import TimePointConfiguration from '../TimePointConfiguration'
import TimePointManager from '../TimePointManager'
import { useParams } from 'react-router-dom'
import { DEFAULT_SITE } from '@common/constants/study'
import { useGetSubjectsList } from '@features/subject/hooks/useGetSubjectsList'
import { useGetStudyById } from '@study_setup/hooks/useStudyQueries'
import SubjectStatusChip from '../SubjectTable/SubjectStatusChip'
import { findSubmissionById } from '../../utils'
import { useGetTimePointSubmissionDetails } from '@features/subject/hooks/useGetTimePointSubmissionDetails'

export default function SubjectDetails() {
  const { studyId, subjectId, timepointSubmissionId } = useParams<{
    studyId: string
    subjectId: string
    timepointSubmissionId: string
  }>()
  const { data, error, isLoading } = useGetSubjectsList(['SubjectsList'], {
    site_id: DEFAULT_SITE.site_id,
    study_id: studyId!,
  })
  const { data: studyData, isLoading: isStudyLoading } = useGetStudyById(studyId!)
  const { data: timePointSubmissionData, isLoading: isTimePointSubmissionLoading } = useGetTimePointSubmissionDetails(
    ['TimePointsDetails', timepointSubmissionId!],
    {
      site_id: DEFAULT_SITE.site_id,
      study_id: studyId!,
      subject_id: subjectId!,
      timepoint_submission_id: timepointSubmissionId!,
    },
  )

  if (isLoading || isStudyLoading || isTimePointSubmissionLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  const renderBreadCrumbs = () => {
    return [
      {
        title: 'Home',
        route_key: RouteKeyEnum.HOME,
      },
      {
        title: studyData?.nickname || '',
        route_key: RouteKeyEnum.STUDY_DETAILS,
        params: { studyId: studyId },
      },
      {
        title: `Subject ${timePointSubmissionData?.data?.subject.pseudo_id}`,
        route_key: RouteKeyEnum.SUBJECTS,
      },
    ]
  }

  const selectedSubmission = findSubmissionById(
    data?.data?.subjects?.find((x) => x.subject_id === subjectId)?.study_timepoints || [],
    timepointSubmissionId || '',
  )

  const containerParams =
    timePointSubmissionData?.data?.timepoint_submission.container_requirements.map((item) => {
      const containerSubmission = timePointSubmissionData?.data?.timepoint_submission.container_submissions.find(
        (x) =>
          x.container_requirement_id ===
          timePointSubmissionData?.data?.timepoint_submission.container_requirements.find(
            (req) => req.modality === item.modality,
          )?.container_requirement_id,
      )

      return {
        name: item.name,
        type: item.container_type,
        isOptional: item.is_optional || false,
        modality: item.modality as string,
        containerSubmissionId: containerSubmission?.container_submission_id || '',
        eFormFields: item.timepoint_container_eform_fields,
        eFormValues: containerSubmission?.container_eform_submission,
        status: containerSubmission?.status,
        noFilesSubmitted: !!containerSubmission?.no_file_submission_reason,
      }
    }) || []

  return (
    <Stack px={3}>
      <Breadcrumb items={renderBreadCrumbs() as BreadcrumbItem[]} />
      <Box display="flex" mt={2} alignItems="center" gap={2}>
        <Typography variant="h4">Subject {timePointSubmissionData?.data?.subject.pseudo_id}</Typography>
        <SubjectStatusChip subjectStatus={timePointSubmissionData?.data?.subject.status} />
      </Box>
      <Box display="flex" mt={1} alignItems="center" gap={1}>
        <CalendarMonthRoundedIcon sx={{ color: 'text.secondary' }} fontSize="small" />
        <Typography variant="body1" color="text.secondary">
          First visit: {timePointSubmissionData?.data?.subject.first_visit || '-'}
        </Typography>
      </Box>
      <Grid container columnSpacing={2} mt={4}>
        <Grid size={8}>
          <TimePointConfiguration
            timePointFormId={
              timePointSubmissionData?.data?.timepoint_submission.timepoint_requirement?.timepoint_form_id
            }
            eFormFields={
              timePointSubmissionData?.data?.timepoint_submission.timepoint_requirement?.timepoint_form_fields
            }
            eFormValues={timePointSubmissionData?.data?.timepoint_submission.timepoint_form_submission}
            containersParams={containerParams}
            status={selectedSubmission?.status}
            name={selectedSubmission?.name || ''}
          />
        </Grid>
        <Grid size={4}>
          <TimePointManager
            timePoints={
              data?.data?.subjects
                ?.find((x) => x.subject_id === subjectId)
                ?.study_timepoints?.map((timepoint) => ({
                  name: timepoint.name,
                  id: timepoint.timepoint_id,
                  children:
                    timepoint.timepoint_submissions?.map((submission) => ({
                      name: submission.name,
                      id: submission.timepoint_submission_id,
                    })) || [],
                })) || []
            }
            selectedTimePointSubmission={timepointSubmissionId!}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}
