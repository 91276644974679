import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { CleaningServicesRounded } from '@mui/icons-material'
import { PopupState } from 'material-ui-popup-state/hooks'
import { useResetTasksMutation } from '@reader_task_list_view/hooks/useResetTasksMutation'
import { toast } from 'sonner'
import Toast from './Toast'
import { AxiosError } from 'axios'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 1,
}

export default function ResetModal({
  read_task_id,
  read_pseudo_id,
  study_id,
  popupState,
}: {
  read_task_id: string
  read_pseudo_id: number
  study_id: string
  popupState: PopupState
}) {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    popupState.close()
  }
  const { mutateAsync: resetTask } = useResetTasksMutation()

  const handleResetTask = async (task_ids: string[], study_id: string) => {
    await resetTask({
      query: {
        read_task_ids: task_ids,
        study_id: study_id,
      },
    })
      .then(() => {
        toast(<Toast message="Your request was sent successfully" variant="success" data-testid="success-toast" />)
        handleClose()
      })
      .catch((error) => {
        toast(<Toast message={(error as AxiosError).message} variant="error" data-testid="error-toast" />)
      })
  }

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: 'flex',
          justifyContent: 'start',
          width: '100%',
          gap: 1.5,
          alignItems: 'center',
        }}
        data-testid="reset-task-button"
        tabIndex={0}
        aria-label="Reset Task Button"
      >
        <CleaningServicesRounded
          sx={{
            color: 'text.secondary',
          }}
        />
        <Typography variant="body1">Reset Task</Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        data-testid="reset-modal"
        aria-labelledby="reset-modal-title"
        aria-describedby="reset-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              alignSelf: 'stretch',
              width: 600,
            }}
          >
            <Typography variant="h6" color="text.primary" id="reset-modal-title">
              {`Reset task №${read_pseudo_id}?`}
            </Typography>
            <Typography variant="body1" color="text.secondary" id="reset-modal-description">
              All content will be deleted if you reset the task, with no way to restore it. You’ll need to start from
              scratch.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              alignSelf: 'stretch',
              padding: 2,
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              data-testid="cancel-reset-task-button"
              aria-label="Cancel Reset Task"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
              }}
              onClick={() => handleResetTask([read_task_id], study_id)}
              data-testid="confirm-reset-task-button"
              aria-label="Confirm Reset Task"
            >
              Reset task
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
