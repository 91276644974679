import { MenuItem } from '@mui/material'
import { BaseDropdownMenu } from '@common/components/Menu/BaseDropdownMenu'
import { SiteActionMenuProps } from '../types'

export default function SiteActionMenu({ anchorEl, onClose, onDelete, onEdit }: Readonly<SiteActionMenuProps>) {
  return (
    <BaseDropdownMenu anchorEl={anchorEl} onClose={onClose}>
      <MenuItem onClick={onEdit}>Edit Site</MenuItem>
      <MenuItem onClick={onDelete}>Delete Site</MenuItem>
    </BaseDropdownMenu>
  )
}
