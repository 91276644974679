import SearchIcon from '@mui/icons-material/Search'
import { Avatar, Box, Button, InputAdornment, Menu, MenuItem, MenuList, TextField, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'
import { useEffect, useState } from 'react'
import { HTTPValidationError, StudyUserRoleAssociation } from '@common/config/api/client'
import { useAssignTasksMutation } from '@qc_task_list_view/hooks/useAssignTasksMutation'
import { toast } from 'sonner'
import Toast from './Toast'
import { AxiosError } from 'axios'
import { useUnassignTasksMutation } from '@qc_task_list_view/hooks/useUnassignTasksMutation'
import { PopupAssignMenuListProps } from '@qc_task_list_view/qc_task_list.types'
import { useParams } from 'react-router-dom'

export default function PopupAssignMenuList({
  tasksAndAssignees,
  currentAuthedUserId,
  assigneeList,
  isBulkAssign = false,
}: PopupAssignMenuListProps) {
  const [query, setQuery] = useState<string>('')
  const [results, setResults] = useState<StudyUserRoleAssociation[]>([])
  const { mutateAsync: assignTask } = useAssignTasksMutation()
  const { mutateAsync: unassignTask } = useUnassignTasksMutation()
  const { studyId } = useParams()

  useEffect(() => {
    setResults(
      assigneeList.filter(
        (item) =>
          tasksAndAssignees.some((task) => task.assignee?.user_id !== item.user_id) &&
          item.user_id !== currentAuthedUserId,
      ),
    )
  }, [assigneeList, tasksAndAssignees])

  const handleAssignTask = async (user_id: string, task_ids: string[]) => {
    await assignTask({
      study_id: studyId!,
      study_qc_task_ids: task_ids,
      user_id: user_id,
    })
      .then((res) => {
        toast(
          <Toast
            message="You were assigned task"
            task_ids={res.processed_task_ids.map((task) => {
              return task.split('-')[0]
            })}
            variant="success"
            data-testid="success-toast"
          />,
        )
        setResults(
          assigneeList.filter(
            (item) =>
              tasksAndAssignees.some((task) => task.assignee?.user_id !== item.user_id) &&
              item.user_id !== currentAuthedUserId &&
              item.user_id !== user_id,
          ),
        )
      })
      .catch((error: AxiosError<HTTPValidationError>) => {
        const errorMessage = Array.isArray(error.response?.data?.detail)
          ? error.response?.data?.detail.map((err) => err.msg).join(', ')
          : error.response?.data?.detail || error.message
        toast(<Toast message={errorMessage} variant="error" data-testid="error-toast" />)
      })
  }

  const handleUnassignTask = async (task_ids: string[]) => {
    await unassignTask({
      study_qc_task_ids: task_ids,
      study_id: studyId!,
    })
      .then((res) => {
        toast(
          <Toast
            message="You unassigned task"
            task_ids={res.processed_task_ids.map((task) => {
              return task.split('-')[0]
            })}
            variant="success"
            data-testid="success-toast"
          />,
        )
      })
      .catch((error: AxiosError<HTTPValidationError>) => {
        const errorMessage = Array.isArray(error.response?.data?.detail)
          ? error.response?.data?.detail.map((err) => err.msg).join(', ')
          : error.response?.data?.detail || error.message
        toast(<Toast message={errorMessage} variant="error" data-testid="error-toast" />)
      })
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    setQuery(input)

    if (input) {
      setResults(assigneeList.filter((item) => item.name?.toLowerCase().includes(input.toLowerCase())))
    } else {
      setResults(assigneeList)
    }
  }

  const areAllTasksAssignedToMe = tasksAndAssignees.every(
    (task) => task.assignee && task.assignee.user_id === currentAuthedUserId,
  )

  const isATaskAssigned = tasksAndAssignees.some((task) => task.assignee)
  const taskIds = tasksAndAssignees.map((task) => task.taskId)

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <Fragment>
          <Box {...bindTrigger(popupState)}>
            {isBulkAssign ? (
              <Button variant="contained" size="medium" color="primary">
                Assign tasks
              </Button>
            ) : (
              <Typography
                sx={{
                  color: tasksAndAssignees[0].assignee ? 'inherit' : '#006B8C',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: tasksAndAssignees[0].assignee ? 'underline' : 'none',
                  },
                }}
                data-testid="assign-task"
              >
                {tasksAndAssignees[0].assignee ? tasksAndAssignees[0].assignee.user_name : 'Assign task'}
              </Typography>
            )}
          </Box>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: 2,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  width: 350,
                  padding: 0,
                }}
                onKeyDown={(e) => e.stopPropagation()}
                data-testid="popup-menu-list-item-search"
              >
                <TextField
                  variant="outlined"
                  size="small"
                  onChange={handleSearch}
                  value={query}
                  label="Search"
                  fullWidth
                  sx={{
                    boxShadow: 1,
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </MenuItem>
              {!areAllTasksAssignedToMe && (
                <MenuItem
                  onClick={popupState.close}
                  sx={{
                    width: '100%',
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      padding: 1,
                      width: '100%',
                    }}
                    data-testid="assign-to-me"
                    onClick={() => {
                      handleAssignTask(currentAuthedUserId, taskIds)
                    }}
                  >
                    <PersonAddRoundedIcon
                      sx={{
                        marginRight: 1.5,
                        width: 24,
                        height: 24,
                        color: 'text.secondary',
                      }}
                    />
                    <Typography>Assign to me</Typography>
                  </Box>
                </MenuItem>
              )}
              {!isBulkAssign && isATaskAssigned && (
                <MenuItem
                  onClick={popupState.close}
                  sx={{
                    width: '100%',
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      padding: 1,
                      width: '100%',
                    }}
                    data-testid="unassign-task"
                    onClick={() => {
                      handleUnassignTask(taskIds)
                    }}
                  >
                    <PersonRemoveRoundedIcon
                      sx={{
                        marginRight: 1.5,
                        width: 24,
                        height: 24,
                        color: 'text.secondary',
                      }}
                    />
                    <Typography>Unassign</Typography>
                  </Box>
                </MenuItem>
              )}
              {results.map((assignee) => (
                <MenuItem
                  onClick={popupState.close}
                  key={assignee.user_id}
                  data-testid="assign-to-user"
                  sx={{
                    width: '100%',
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      padding: 1,
                      width: '100%',
                    }}
                    onClick={() => {
                      handleAssignTask(assignee.user_id, taskIds)
                    }}
                  >
                    <Avatar sx={{ marginRight: 1.5, width: 24, height: 24 }}>
                      <Typography variant="overline" color="text.primary">
                        {assignee.name
                          .split(' ')
                          .map((word) => word[0])
                          .join('')}
                      </Typography>
                    </Avatar>
                    <Typography variant="body1">{assignee.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
