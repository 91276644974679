import {
  UnassignQcTaskQcTasksUnassignPatchResponse,
  Body_unassign_qc_task_qc_tasks_unassign_patch,
  HTTPValidationError,
  unassignQcTaskQcTasksUnassignPatch,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useUnassignTasksMutation = (): UseMutationResult<
  UnassignQcTaskQcTasksUnassignPatchResponse,
  AxiosError<HTTPValidationError>,
  Body_unassign_qc_task_qc_tasks_unassign_patch
> => {
  return useMutation<
    UnassignQcTaskQcTasksUnassignPatchResponse,
    AxiosError<HTTPValidationError>,
    Body_unassign_qc_task_qc_tasks_unassign_patch
  >({
    mutationFn: async (body) => {
      const response = await unassignQcTaskQcTasksUnassignPatch({
        body: body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTasksList'] })
    },
  })
}
