import { useMutation } from '@tanstack/react-query'
import {
  assignUsersToStudyStudiesStudyIdUsersAssignPost,
  AssignUsersToStudyStudiesStudyIdUsersAssignPostResponse,
  HTTPValidationError,
  UserAssignCreateSchema,
} from '@common/config/api/client'
import { AxiosError } from 'axios'
import { queryClient } from '@common/config/api/queryClient'
import queryKeyFactory from '../queryKeyFactory'

interface Params {
  studyId: string
  assignments: UserAssignCreateSchema
}

export default function useAssignUsersToStudy() {
  return useMutation<AssignUsersToStudyStudiesStudyIdUsersAssignPostResponse, AxiosError<HTTPValidationError>, Params>({
    mutationFn: async ({ studyId, assignments }) => {
      const response = await assignUsersToStudyStudiesStudyIdUsersAssignPost({
        path: { study_id: studyId },
        body: assignments,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeyFactory.allUsers() })
    },
  })
}
