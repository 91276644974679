import { UserListParams } from './types'

const queryKeyFactory = {
  users: (params: UserListParams) => [
    'users',
    params.user_type,
    params.search,
    params.limit,
    params.next_cursor,
    params.previous_cursor,
    params.order_by_field,
    params.asc_order,
  ],
  allUsers: () => ['users'],
  companiesMetadata: () => ['companiesMetadata'],
}

export default queryKeyFactory
