import { useQuery } from '@tanstack/react-query'
import {
  getStudySiteByIdStudiesStudyIdSitesSiteIdGet,
  getStudySitesMetadataStudiesStudyIdSitesMetadataGet,
  listStudySitesStudiesStudyIdSitesGet,
} from '@common/config/api/client'
import { SitesParams } from '../types'
import { transformGetSitesResponse, transformGetStduySitesMetadataResponse, transformGetStudySiteByIdResponse } from '.'
import queryKeyFactory from '../queryKeyFactory'

export const useGetStudySites = (params: SitesParams) => {
  return useQuery({
    queryKey: queryKeyFactory.studySites(params),
    queryFn: async () => {
      const response = await listStudySitesStudiesStudyIdSitesGet({
        path: {
          study_id: params.study_id,
        },
        query: {
          search: params.search,
          limit: params.limit,
          next_cursor: params.next_cursor,
          previous_cursor: params.previous_cursor,
          order_by_field: params.order_by_field,
          asc_order: params.asc_order,
        },
      })
      return response.data
    },
    select: transformGetSitesResponse,
    enabled: !!params.study_id,
  })
}

export const getStudySiteQuery = ({ studyId, siteId }: { studyId: string; siteId: string }) => ({
  queryKey: queryKeyFactory.studySite(studyId, siteId),
  queryFn: async () => {
    const response = await getStudySiteByIdStudiesStudyIdSitesSiteIdGet({
      path: { study_id: studyId, site_id: siteId },
      throwOnError: true,
    })
    return response.data
  },
  select: transformGetStudySiteByIdResponse,
})

export const useGetStudySiteById = ({ studyId, siteId }: { studyId: string; siteId: string }) => {
  return useQuery({
    ...getStudySiteQuery({ studyId, siteId }),
    enabled: !!studyId && !!siteId,
  })
}

export const useGetStudySitesMetadata = (studyId: string) => {
  return useQuery({
    queryKey: queryKeyFactory.studySitesMetadata(studyId!),
    queryFn: async () => {
      if (!studyId) {
        throw new Error('studyId is required')
      }
      const response = await getStudySitesMetadataStudiesStudyIdSitesMetadataGet({
        path: { study_id: studyId },
        throwOnError: true,
      })
      return response.data
    },
    select: transformGetStduySitesMetadataResponse,
    enabled: !!studyId,
    retry: false,
  })
}
