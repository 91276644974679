import { Box, Button } from '@mui/material'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField } from '@common/components/Form/TextField'
import ESignDialog from '@features/subject/components/eSignDialog'
import { AbstractEsignContext } from '@common/config/api/client'
import { useState } from 'react'
import { Dropdown } from '@common/components/Form/Dropdown'

export type NoFilesBlockProps = {
  containerSubmissionId: string
  onSign: (containerSubmissionId: string) => void
}

const NoFilesSchema = z.object({
  reason: z.string({ message: 'Required' }).min(1, { message: 'Required' }),
  comment: z.string().min(1, { message: 'Comment required' }),
})

const REASON_OPTIONS = [
  {
    value: 'No images available for this data type',
    label: 'No images available for this data type',
  },
  {
    value: 'No images available for this visit',
    label: 'No images available for this visit',
  },
  {
    value: 'Patient related issues',
    label: 'Patient related issues',
  },
  {
    value: 'Other, please specify',
    label: 'Other, please specify',
  },
]

type NoFilesFormParams = z.infer<typeof NoFilesSchema>

export default function NoFilesBlock({ containerSubmissionId, onSign }: NoFilesBlockProps) {
  const [eSignDialogState, setESignDialogState] = useState<{
    payload: Record<string, unknown> & AbstractEsignContext
    open: boolean
  }>({
    payload: {
      type: 'timepoint_container_submission',
    },
    open: false,
  })
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<NoFilesFormParams>({
    mode: 'onChange',
    resolver: zodResolver(NoFilesSchema),
  })

  const handleSignClick: SubmitHandler<NoFilesFormParams> = ({ reason, comment }) => {
    setESignDialogState({
      payload: {
        type: 'timepoint_container_submission',
        user_role_association_id: '9f6239c5-a75e-4cc4-a632-264ec30d61f3', // temp value
        container_submissions: [
          {
            container_submission_id: containerSubmissionId,
            no_file_submission_reason: reason,
            comment,
          },
        ],
      },
      open: true,
    })
  }

  return (
    <Box bgcolor="#F0F4F5" borderRadius={3} p={2} mt={2}>
      <form onSubmit={handleSubmit(handleSignClick)}>
        <Dropdown
          control={control}
          name="reason"
          label="Reason"
          options={REASON_OPTIONS}
          sx={{ mb: 2 }}
          size="small"
          helperText=""
        />
        <TextField
          name="comment"
          control={control}
          label="Comment"
          multiline
          rows={6}
          fullWidth
          helperText={!isValid ? 'Provide a reason and write a brief clarification' : 'Briefly clarify your reason'}
          sx={{ mb: 1 }}
          size="small"
        />
        <Box display="flex" justifyContent="flex-end">
          <Button color="secondary" variant="contained" type="submit">
            Sign and save
          </Button>
        </Box>
      </form>
      {eSignDialogState.open && (
        <ESignDialog
          payload={eSignDialogState.payload}
          onClose={() => {
            setESignDialogState({ ...eSignDialogState, open: false })
            onSign(containerSubmissionId)
          }}
        />
      )}
    </Box>
  )
}
