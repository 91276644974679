import { Badge, Button, Checkbox, Divider, IconButton, Paper, Stack, Typography } from '@mui/material'
import { AddRounded, ContentCopyRounded, DeleteOutlineRounded } from '@mui/icons-material'
import { QcStudyDefinitionCreateFormValues } from './StudyAndSeriesDefinitionValidationSchema'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import SeriesDefinitionForm from './SeriesDefinitionForm'
import { Fragment } from 'react/jsx-runtime'
import MergeModal from './MergeModal'
import { QcTimepointContainerSubmission } from '@common/config/api/client'
import { v4 as uuidv4 } from 'uuid'
import { useStudyMetadataOptions } from '@study_setup/hooks/useStudyMetadataOptions'
import { useParams } from 'react-router-dom'

export default function SeriesDefinitionFormList({
  control,
  qcTimepointContainerSubmissions,
}: {
  control: Control<QcStudyDefinitionCreateFormValues>
  qcTimepointContainerSubmissions: QcTimepointContainerSubmission[]
}) {
  const { fields, append, remove, insert, update, replace } = useFieldArray({
    control,
    name: 'series_definitions',
  })

  const { studyId } = useParams()
  const { seriesLabelOptions, error, isLoading } = useStudyMetadataOptions(studyId!, ['series_labels'])

  const [series_definitions, exam_metadata_ids] = useWatch({
    control,
    name: ['series_definitions', 'exam_metadata_ids'],
  })

  const selectedNewSeriesDescriptionOptions = series_definitions.map((item) => item.series_label_id)

  const availableSourceSeriesDescriptionOptions = qcTimepointContainerSubmissions
    .map((submission) =>
      submission.exam_metadata
        .filter((metadata) => exam_metadata_ids.includes(metadata.exam_metadata_id))
        .map((metadata) =>
          metadata.series.map((series) => {
            return {
              value: series.series_metadata_id,
              label: series.description || '',
            }
          }),
        )
        .flat(),
    )
    .flat()

  const selectedSeries = series_definitions.filter((item) => item.is_selected)
  const unusedSourceSeriesDescriptions = availableSourceSeriesDescriptionOptions.filter(
    (item) => !series_definitions.map((item) => item.series_metadata_id).includes(item.value),
  )
  const allSelected = series_definitions.length > 0 && series_definitions.every((item) => item.is_selected)
  const noneSelected = series_definitions.every((item) => !item.is_selected)
  const numberOfSelectedSeries = series_definitions.filter((item) => item.is_selected).length

  const bulkDelete = () => {
    const filtered = series_definitions.filter((item) => !item.is_selected)
    replace(filtered)
  }

  const bulkDuplicate = () => {
    const newSeries = []
    for (const item of series_definitions) {
      newSeries.push(item)
      if (item.is_selected) {
        newSeries.push({
          ...item,
          image_number: '',
          new_series_description: '',
          is_selected: false,
        })
      }
    }
    replace(newSeries)
  }

  const toggleAllSelected = (checked: boolean) => {
    series_definitions.forEach((item, index) => {
      update(index, { ...item, is_selected: checked })
    })
  }

  const handleMerge = (new_series_description: string, index?: number) => {
    if (index) {
      const newSeries = [...series_definitions]

      const [item] = newSeries.splice(index, 1)

      item.series_label_id = new_series_description

      let lastOccurrenceIndex = -1
      for (let i = newSeries.length - 1; i >= 0; i--) {
        if (newSeries[i].series_label_id === new_series_description) {
          lastOccurrenceIndex = i
          break
        }
      }
      if (lastOccurrenceIndex >= 0) {
        newSeries.splice(lastOccurrenceIndex + 1, 0, item)
      } else {
        newSeries.push(item)
      }
      replace(newSeries)
    } else {
      const newSeries = series_definitions.filter((item) => !item.is_selected)
      const updatedItems = selectedSeries.map((item) => ({
        ...item,
        series_label_id: new_series_description,
        is_selected: false,
      }))

      for (const item of updatedItems) {
        let lastOccurrenceIndex = -1
        for (let i = newSeries.length - 1; i >= 0; i--) {
          if (newSeries[i].series_label_id === new_series_description) {
            lastOccurrenceIndex = i
            break
          }
        }
        if (lastOccurrenceIndex >= 0) {
          newSeries.splice(lastOccurrenceIndex + 1, 0, item)
        } else {
          newSeries.push(item)
        }
      }
      replace(newSeries)
    }
  }

  const bulkAdd = () => {
    unusedSourceSeriesDescriptions.forEach((item) => {
      append({
        series_label_id: '',
        submission_series_id: uuidv4(),
        series_metadata_id: item.value,
        instance_number_ranges: [],
        is_selected: false,
        max_instance_number: 0,
        order: 1,
      })
    })
  }

  if (isLoading) {
    return <Stack>Loading...</Stack>
  }
  if (error) {
    return <Stack>Error: {error.message}</Stack>
  }
  const newSeriesDescriptions = seriesLabelOptions

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" justifyContent="start" spacing={1} pl={1.5}>
          <Checkbox
            sx={{
              width: 32,
              height: 32,
            }}
            checked={allSelected}
            indeterminate={!noneSelected && !allSelected}
            onChange={(e) => toggleAllSelected(e.target.checked)}
          />
          {noneSelected ? (
            <Stack direction="row" alignItems="center" justifyContent="start" spacing={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Series
              </Typography>
              <Badge badgeContent={fields.length} color="secondary" />
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" justifyContent="start" spacing={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'text.secondary',
                }}
              >
                {`${numberOfSelectedSeries} series selected`}
              </Typography>
              <Stack direction="row" alignItems="center" justifyContent="start" spacing={1}>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                  onClick={bulkDuplicate}
                >
                  <ContentCopyRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                </IconButton>
                <IconButton
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                >
                  <MergeModal
                    handleMerge={handleMerge}
                    control={control}
                    newSeriesDescriptions={newSeriesDescriptions}
                  />
                </IconButton>
                <IconButton
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                  onClick={bulkDelete}
                >
                  <DeleteOutlineRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          )}
          {}
        </Stack>
        <Button
          variant="contained"
          sx={{
            color: 'text.primary',
            py: 0.5,
            px: 1.5,
          }}
          onClick={bulkAdd}
          color="secondary"
        >
          Add all source series
        </Button>
      </Stack>

      <Stack>
        <Paper
          sx={{
            paddingY: 1.5,
            borderRadius: 4,
            bgcolor: 'paper.main',
          }}
        >
          <Stack spacing={1}>
            {fields.map((item, index) => {
              const previousNewSeriesDescriptionOption =
                index > 0 ? series_definitions[index - 1]?.series_label_id || '' : ''

              const currentNewSeriesDescriptionOption = series_definitions[index]?.series_label_id || ''
              const availableNewSeriesDescriptionOptions = newSeriesDescriptions.filter(
                (option) =>
                  currentNewSeriesDescriptionOption === option.value ||
                  !selectedNewSeriesDescriptionOptions.includes(option.value),
              )
              return (
                <Fragment key={item.id}>
                  {index !== 0 &&
                    (currentNewSeriesDescriptionOption !== previousNewSeriesDescriptionOption ||
                      currentNewSeriesDescriptionOption === '') && <Divider />}
                  <SeriesDefinitionForm
                    control={control}
                    index={index}
                    qcTimepointContainerSubmissions={qcTimepointContainerSubmissions}
                    defaultNewSeriesDescriptionValue={currentNewSeriesDescriptionOption}
                    defaultSourceSeriesDescriptionValue={series_definitions[index]?.series_metadata_id || ''}
                    availableNewSeriesDescriptionOptions={availableNewSeriesDescriptionOptions}
                    availableSourceSeriesDescriptionOptions={availableSourceSeriesDescriptionOptions}
                    handleDelete={() => remove(index)}
                    handleDuplicate={() => {
                      insert(index + 1, {
                        ...series_definitions[index],
                        instance_number_ranges: [],
                        series_label_id: '',
                        is_selected: false,
                      })
                    }}
                    handleMerge={handleMerge}
                  />
                </Fragment>
              )
            })}
            <Stack direction="row" alignItems="center" justifyContent="start" paddingX={1.5}>
              <Button
                variant="text"
                sx={{
                  color: 'text.primary',
                  p: 0.5,
                }}
                onClick={() => {
                  append({
                    instance_number_ranges: [],
                    series_label_id: '',
                    submission_series_id: uuidv4(),
                    series_metadata_id: '',
                    is_selected: false,
                    max_instance_number: 0,
                    order: 1,
                  })
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="start" spacing={1}>
                  <AddRounded />
                  <Typography variant="button"> Add series </Typography>
                </Stack>
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  )
}
