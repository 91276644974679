import { useMutation } from '@tanstack/react-query'
import {
  unassignUserFromStudyStudiesStudyIdUsersUserIdAssignDelete,
  HTTPValidationError,
  UnassignUserFromStudyStudiesStudyIdUsersUserIdAssignDeleteResponse,
} from '@common/config/api/client'
import { AxiosError } from 'axios'
import { queryClient } from '@common/config/api/queryClient'
import queryKeyFactory from '../queryKeyFactory'

interface Params {
  studyId: string
  userId: string
}

export default function useUnassignUserFromStudy() {
  return useMutation<
    UnassignUserFromStudyStudiesStudyIdUsersUserIdAssignDeleteResponse,
    AxiosError<HTTPValidationError>,
    Params
  >({
    mutationFn: async ({ studyId, userId }) => {
      const response = await unassignUserFromStudyStudiesStudyIdUsersUserIdAssignDelete({
        path: {
          study_id: studyId,
          user_id: userId,
        },
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeyFactory.allUsers() })
    },
  })
}
