import { ChatBubbleOutlineRounded } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import ExamDetailsList from './ExamDetailsList'
import ExamReason from './ExamReason'
import { QcTimepointContainerSubmission } from '@common/config/api/client'
import { Fragment } from 'react/jsx-runtime'

export default function Exam({ container }: { container: QcTimepointContainerSubmission }) {
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        {container.no_file_submission_reason ? (
          <Fragment>
            <Stack>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {container.container_requirement_name}
              </Typography>
            </Stack>
            <ExamReason reason={container.no_file_submission_reason} comment={container.comments} />
          </Fragment>
        ) : (
          <Fragment>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {container.container_requirement_name}
              </Typography>
              <IconButton>
                <ChatBubbleOutlineRounded sx={{ color: 'text.secondary', width: 20, height: 20 }} />
              </IconButton>
            </Stack>
            <ExamDetailsList container={container} />
          </Fragment>
        )}
      </Stack>
    </Stack>
  )
}
