import { metadataStudiesStudyIdUsersMetadataGet } from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'
import queryKeyFactory from '../queryKeyFactory'

export default function useCompaniesMetadata(studyId: string) {
  return useQuery({
    queryKey: queryKeyFactory.companiesMetadata(),
    queryFn: async () => {
      const response = await metadataStudiesStudyIdUsersMetadataGet({ path: { study_id: studyId } })
      return response.data ?? { companies: [] }
    },
  })
}
