import { useEffect, useMemo } from 'react'
import { TableBody, TableRow, TableCell, Checkbox } from '@mui/material'
import TaskTypeChip from './TaskTypeChip'
import { EnhancedTableBodyProps } from '../qc_task_list.types'
import TaskStateChip from './TaskStateChip'
import QueryStatusChip from './QueryStatusChip'
import { formatTaskDate, getComparator, getTabIndex } from '../utils'
import { useAssignTasksMutation } from '@qc_task_list_view/hooks/useAssignTasksMutation'
import { useActiveTab } from '@common/hooks/useActiveTab'
import { QCTaskListTabsKeyEnum } from '@qc_task_list_view/constant'
import { toast } from 'sonner'
import { AxiosError } from 'axios'
import Toast from './Toast'
import { useTabOptionalComponent } from '@common/hooks/useTabOptionalComponent'
import { useParams } from 'react-router-dom'
import { getPrimarySitePseudoId } from '@common/utils/getPrimarySitePseudoID'
import { HTTPValidationError } from '@common/config/api/client'

export default function EnhancedTableBodyTaskPool({
  rows,
  order,
  orderBy,
  selected,
  handleClick,
  authedUserId,
}: EnhancedTableBodyProps) {
  const { mutateAsync: assignTask } = useAssignTasksMutation()
  const { setCurrentActiveTab } = useActiveTab()
  const isSelected = (id: string | null | undefined) => selected?.indexOf(id) !== -1
  const { isComponentUsed, setIsComponentUsed } = useTabOptionalComponent()
  const { studyId } = useParams<{ studyId: string }>()

  const visibleRows = useMemo(() => {
    const comparator =
      orderBy === 'sites.site_pseudo_id'
        ? getComparator(order, orderBy, (item) => getPrimarySitePseudoId(item.sites))
        : getComparator(order, orderBy)
    return [...rows].sort(comparator)
  }, [order, orderBy, rows])

  const handleAssignTask = async (task_ids: string[]) => {
    if (authedUserId) {
      await assignTask({
        study_id: studyId!,
        study_qc_task_ids: task_ids,
        user_id: authedUserId,
      })
        .then((res) => {
          toast(
            <Toast
              message="You were assigned task"
              task_ids={res.processed_task_ids.map((task) => {
                return task.split('-')[0]
              })}
              variant="success"
              data-testid="success-toast"
            />,
          )
          setCurrentActiveTab(getTabIndex(QCTaskListTabsKeyEnum.MY_TASKS))
        })
        .catch((error: AxiosError<HTTPValidationError>) => {
          const errorMessage = Array.isArray(error.response?.data?.detail)
            ? error.response?.data?.detail.map((err) => err.msg).join(', ')
            : error.response?.data?.detail || error.message
          toast(<Toast message={errorMessage} variant="error" data-testid="error-toast" />)
        })
    }
  }

  useEffect(() => {
    if (isComponentUsed && selected && selected.length > 0) {
      handleAssignTask(selected.filter((id) => id !== null && id !== undefined))
      setIsComponentUsed(false)
    }
  }, [isComponentUsed])

  return (
    <TableBody>
      {visibleRows.map((row, index) => {
        const isItemSelected = isSelected(row.task_id)
        const labelId = `enhanced-table-checkbox-${index}`

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.task_id}
            selected={isItemSelected}
            sx={{
              borderBottom: '1px solid rgba(220, 227, 229, 1)',
              cursor: 'pointer',
            }}
            onClick={() => handleAssignTask([row.task_id])}
          >
            <TableCell
              padding="checkbox"
              onClick={(event) => {
                if (handleClick) {
                  handleClick(event, row.task_id)
                }
                event.stopPropagation()
              }}
              data-testid="checkbox-cell"
            >
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            </TableCell>
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="site-pseudo-id-cell">
              {getPrimarySitePseudoId(row.sites)}
            </TableCell>
            <TableCell align="left" data-testid="subject-pseudo-id-cell">
              {getPrimarySitePseudoId(row.sites)} - {row.subject.subject_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="timepoint-name-cell">
              {row.timepoint_requirement.name}
            </TableCell>
            <TableCell align="left" data-testid="task-type-cell">
              <TaskTypeChip taskType={row.task_type} />
            </TableCell>
            <TableCell align="left" data-testid="task-state-cell">
              <TaskStateChip taskState={row.task_state} />
            </TableCell>
            <TableCell align="left" data-testid="query-status-cell">
              <QueryStatusChip queryStatus={row.query_status} />
            </TableCell>
            <TableCell align="left" data-testid="create-date-cell">
              {formatTaskDate(row.create_date)}
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
