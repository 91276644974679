import { MenuItem } from '@mui/material'
import { BaseDropdownMenu } from '@common/components/Menu/BaseDropdownMenu'

interface Props {
  anchorEl: HTMLElement | null
  onClose: () => void
  onUserTypeSelect: (userType: string) => void
}

export default function AddUserMenu({ anchorEl, onClose, onUserTypeSelect }: Readonly<Props>) {
  return (
    <BaseDropdownMenu anchorEl={anchorEl} onClose={onClose}>
      <MenuItem
        onClick={() => {
          onUserTypeSelect('assign')
          onClose()
        }}
      >
        Assign existing user
      </MenuItem>
      <MenuItem
        onClick={() => {
          onUserTypeSelect('create')
          onClose()
        }}
      >
        Create new user
      </MenuItem>
    </BaseDropdownMenu>
  )
}
