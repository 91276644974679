import { Button, Stack, Typography } from '@mui/material'
import { TextField } from '@common/components/Form/TextField'
import { Dropdown } from '@common/components/Form/Dropdown/Dropdown'
import { useForm, FormProvider } from 'react-hook-form'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CompaniesMetadataSchema, UserCreateSchema, UserSchema } from '@common/config/api/client/types.gen'

interface Props {
  user: UserSchema | undefined
  companiesMetadata: CompaniesMetadataSchema | undefined
  onUpdateUser?: (userData: UserCreateSchema) => Promise<void>
}

export default function ProfileInformation({ user, companiesMetadata, onUpdateUser }: Props) {
  const [searchParams] = useSearchParams()
  const [isEditMode, setIsEditMode] = useState(searchParams.get('edit') === 'true')
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(user?.company_id ?? '')

  const methods = useForm<UserCreateSchema>({
    defaultValues: {
      name: user?.user_name ?? '',
      email: user?.user_email ?? '',
      company_id: user?.company_id ?? '',
      role_id: user?.role_id ?? '',
      phone: user?.phone ?? '',
    },
  })

  const companyOptions = useMemo(() => {
    if (!companiesMetadata?.companies) return []
    return companiesMetadata?.companies.map((company) => ({
      label: company.company_name,
      value: company.company_id,
    }))
  }, [companiesMetadata])

  const roleOptions = useMemo(() => {
    if (!companiesMetadata?.companies || !selectedCompanyId) return []

    const selectedCompany = companiesMetadata.companies.find((company) => company.company_id === selectedCompanyId)

    return (
      selectedCompany?.roles.map((role) => ({
        label: role.role_name,
        value: role.role_id,
      })) ?? []
    )
  }, [companiesMetadata, selectedCompanyId])

  const handleCompanyChange = (value: string) => {
    setSelectedCompanyId(value)
    methods.setValue('role_id', '')
  }

  const handleCancel = () => {
    methods.reset()
    setIsEditMode(false)
  }

  const handleSave = async (data: UserCreateSchema) => {
    try {
      if (!onUpdateUser) return
      await onUpdateUser({
        name: data.name,
        email: data.email,
        phone: data.phone,
        company_id: data.company_id,
        role_id: data.role_id,
      })
      setIsEditMode(false)
    } catch (error) {
      console.error('Error saving user:', error)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSave)}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" mt={3}>
              {user?.user_name}'s profile information
            </Typography>
            {!isEditMode && (
              <Button variant="contained" onClick={() => setIsEditMode(true)} sx={{ backgroundColor: '#0F5499' }}>
                Edit personal information
              </Button>
            )}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              control={methods.control}
              name="name"
              label="Name"
              disabled={!isEditMode}
              size="small"
              sx={{ width: '50%' }}
            />
            <TextField name="phone" label="Phone number" disabled={!isEditMode} size="small" sx={{ width: '50%' }} />
          </Stack>
          <TextField control={methods.control} name="email" label="Email" disabled={!isEditMode} size="small" />
          <Stack direction="row" spacing={2}>
            <Dropdown
              control={methods.control}
              name="company_id"
              label="Company name"
              options={companyOptions}
              disabled={!isEditMode}
              size="small"
              sx={{ width: '50%' }}
              onChange={(_, value) => {
                if (value && typeof value === 'object' && 'value' in value) {
                  handleCompanyChange(value.value as string)
                }
              }}
            />
            <Dropdown
              control={methods.control}
              name="role_id"
              label="Role assigned in study"
              options={roleOptions}
              disabled={!isEditMode || !selectedCompanyId}
              size="small"
              sx={{ width: '50%' }}
            />
          </Stack>

          {isEditMode && (
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Save changes
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
    </FormProvider>
  )
}
