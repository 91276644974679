import { Autocomplete as MuiAutocomplete, TextField, TextFieldProps } from '@mui/material'
import { useController, Control } from 'react-hook-form'
import { useEffect, useState, useRef } from 'react'
import { useDebounce } from '@common/hooks/useDebounce'

export type Option = {
  label: string
  value: string
}

interface Props {
  name: string
  control: Control<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  label: string
  onSearch: (query: string) => void
  options: Option[]
  disabled?: boolean
  rules?: object
  size?: TextFieldProps['size']
  sx?: object
}

export default function Autocomplete({ name, control, label, onSearch, options, disabled, rules, size, sx }: Props) {
  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue)
  const isSelecting = useRef(false)

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  })

  useEffect(() => {
    if (!isSelecting.current) {
      onSearch(debouncedInputValue)
    }
    isSelecting.current = false
  }, [debouncedInputValue, onSearch])

  const selectedOption = options.find((option) => option.value === value) || null

  return (
    <MuiAutocomplete
      options={options}
      value={selectedOption}
      onChange={(_, newValue) => {
        isSelecting.current = true
        onChange(newValue?.value ?? '')
        setInputValue(newValue?.label ?? '')
      }}
      inputValue={inputValue}
      onInputChange={(_, newValue, reason) => {
        if (reason === 'reset') return
        setInputValue(newValue)
      }}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} label={label} error={!!error} helperText={error?.message} size={size} />
      )}
      sx={sx}
    />
  )
}
