import { Badge, Divider, Paper, Stack, Typography } from '@mui/material'
import SourceStudyDescriptionChip from './SourceStudyDescriptionChip'
import SeriesDefinitionInstance from './SeriesDefinitionInstance'
import { Fragment } from 'react/jsx-runtime'
import { QcTaskExamDescription } from '@common/config/api/client'

export default function StudyAndSeriesDefinitionInstance({
  qcTaskExamDescription,
}: {
  qcTaskExamDescription: QcTaskExamDescription
}) {
  return (
    <Stack spacing={3}>
      <Paper
        sx={{
          padding: 2,
          borderRadius: 4,
          bgcolor: 'paper.main',
        }}
      >
        <Stack spacing={2}>
          <Stack spacing={0.25}>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'text.secondary',
              }}
            >
              New study description
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'text.primary',
              }}
            >
              {qcTaskExamDescription.study_description}
            </Typography>
          </Stack>
          <Stack spacing={0.5}>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'text.secondary',
              }}
            >
              Source study description
            </Typography>
            <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="start">
              {qcTaskExamDescription.source_study_description.map((sourceStudyDescription) => (
                <SourceStudyDescriptionChip
                  key={sourceStudyDescription.container_submission_exam_metadata_id}
                  description={sourceStudyDescription.study_description || ''}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Paper>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" justifyContent="start" spacing={2}>
          <Typography
            variant="subtitle1"
            sx={{
              color: 'text.secondary',
            }}
          >
            Series
          </Typography>
          <Badge badgeContent={qcTaskExamDescription.series_descriptions.length} color="secondary" />
        </Stack>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 4,
            bgcolor: 'paper.main',
          }}
        >
          <Stack spacing={0.5}>
            {qcTaskExamDescription.series_descriptions.map((seriesDescription, index) => (
              <Fragment key={seriesDescription.submission_series_id}>
                <SeriesDefinitionInstance seriesDescription={seriesDescription} />
                {index !== qcTaskExamDescription.series_descriptions.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  )
}
