import { useState, MouseEvent, ChangeEvent } from 'react'
import { TransformedSites } from './types'

interface UseSitesTableActionsHandlersProps {
  onEditClick: (siteId: string) => void
  onDeleteSite: (siteId: string) => void
  onBulkDeleteSites: (siteIds: Array<string>) => void
  sites: TransformedSites | undefined
}

export default function useSitesTableActionsHandlers({
  onEditClick,
  onDeleteSite,
  onBulkDeleteSites,
  sites,
}: UseSitesTableActionsHandlersProps) {
  const [selected, setSelected] = useState<string[]>([])
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null)

  const handleEditClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (selectedSiteId) {
      onEditClick(selectedSiteId)
    }
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(sites?.items.map((row) => row.id) ?? [])
    } else {
      setSelected([])
    }
  }
  const handleClick = (_event: MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    const newSelected = selectedIndex === -1 ? [...selected, id] : selected.filter((selectedId) => selectedId !== id)
    setSelected(newSelected)
  }

  const handleOpenMenu = (event: MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
    setSelectedSiteId(id)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
    setSelectedSiteId(null)
  }

  const handleDeleteSite = () => {
    if (selectedSiteId) {
      onDeleteSite(selectedSiteId)
      handleCloseMenu()
    }
  }
  const handleBulkDeleteSites = () => {
    if (selected.length) {
      onBulkDeleteSites(selected)
      setSelected([])
    }
  }

  return {
    selected,
    menuAnchorEl,
    selectedSiteId,
    handleEditClick,
    handleSelectAllClick,
    handleClick,
    handleOpenMenu,
    handleCloseMenu,
    handleDeleteSite,
    handleBulkDeleteSites,
  }
}
