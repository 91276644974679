/**
 * Table component for displaying and managing study sites with sorting, filtering,
 * and action menu functionality
 */
import { MouseEvent } from 'react'
import { TableBody, TableCell, TableRow, Paper, Checkbox, IconButton, Box } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { Order, HeadCell } from '@common/components/Table/table.types'

import SitesTableToolbar from './SitesTableToolbar'
import { TransformedSite, TransformedSites } from '../hooks'
import useSitesTableActionsHandlers from '../hooks/useSitesTableActionsHandlers'
import { SiteData } from '../types'
import SiteActionMenu from './SitesActionMenu'

export interface SiteTableProps {
  page: number
  sites: TransformedSites | undefined
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearch: (value: string) => void
  searchValue?: string
  order: Order
  orderBy: string
  onRequestSort: (event: MouseEvent<unknown>, property: keyof SiteData) => void
  onAddClick: () => void
  onEditClick: (siteId: string) => void
  onDeleteSite: (siteId: string) => void
  onBulkDeleteSites: (siteIds: Array<string>) => void
}

const getHeadCells = (): readonly HeadCell<SiteData>[] => {
  const baseCells: HeadCell<SiteData>[] = [
    { id: 'name', label: 'Site name', disablePadding: false, disableSort: true },
    { id: 'pseudo_id', label: 'Site ID', disablePadding: false },
    { id: 'modalities', label: 'Associated Modalities', disablePadding: false, disableSort: true },
    { id: 'created_by', label: 'Created by', disablePadding: false },
  ]
  return [...baseCells, { id: 'actions', label: '', disablePadding: false }]
}
export default function SitesTable({
  sites,
  onSearch,
  searchValue,
  onRowsPerPageChange,
  handlePageChange,
  page,
  order,
  orderBy,
  onRequestSort,
  onAddClick,
  onEditClick,
  onDeleteSite,
  onBulkDeleteSites,
}: Readonly<SiteTableProps>) {
  const {
    selected,
    handleCloseMenu,
    handleDeleteSite,
    handleEditClick,
    menuAnchorEl,
    handleSelectAllClick,
    handleClick,
    handleOpenMenu,
    handleBulkDeleteSites,
  } = useSitesTableActionsHandlers({
    onEditClick,
    onDeleteSite,
    onBulkDeleteSites,
    sites,
  })

  const headCells = getHeadCells()

  return (
    <Box component="section">
      <SitesTableToolbar onSearch={onSearch} searchValue={searchValue} onAddClick={onAddClick} />
      <Paper sx={{ p: 3 }}>
        <EnhancedTable<SiteData>
          rows={sites?.items ?? []}
          headCells={headCells}
          order={order}
          orderBy={orderBy as keyof SiteData}
          selected={selected}
          page={page}
          next_cursor={sites?.pagination.nextCursor}
          previous_cursor={sites?.pagination.previousCursor}
          rowsPerPage={sites?.pagination.limit ?? 50}
          rowsPerPageOptions={[10, 25, 50]}
          onRequestSort={onRequestSort}
          onSelectAllClick={handleSelectAllClick}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={onRowsPerPageChange}
          onBulkDelete={handleBulkDeleteSites}
        >
          <TableBody>
            {sites?.items.map((row: TransformedSite) => {
              const isItemSelected = selected.indexOf(row.id) !== -1

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.pseudo_id}</TableCell>
                  <TableCell>{row.modalities}</TableCell>
                  <TableCell>{row.createdByUser?.name ?? '-'}</TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>
                    <IconButton size="small" onClick={(e) => handleOpenMenu(e, row.id)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </EnhancedTable>
        <SiteActionMenu
          anchorEl={menuAnchorEl}
          onClose={handleCloseMenu}
          onDelete={handleDeleteSite}
          onEdit={handleEditClick}
        />
      </Paper>
    </Box>
  )
}
