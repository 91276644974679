import { z } from 'zod'
import { SiteAssociationTypeEnum } from '../types'

export const SiteCreateSchema = z
  .object({
    pseudo_id: z.string().min(1, { message: 'Please enter a Site ID' }),
    name: z.string().min(1, { message: 'Please enter a name' }),
    contact_email: z
      .string()
      .min(1, { message: 'Please enter an email' })
      .email({ message: 'Please enter a valid email' }),
    contact_phone: z.string().optional().nullable(),
    modalities: z.array(z.string().uuid()).min(1, { message: 'Please select at least one imaging modality' }),
    site_associations: z.array(z.string().uuid()).optional().nullable(),
    site_association_type: z.string(),
  })
  .refine(
    ({ site_association_type, site_associations }) => {
      const siteAssociationsContainMoreThanOneItem = site_associations && site_associations.length > 1
      if (site_association_type === SiteAssociationTypeEnum.SECONDARY && siteAssociationsContainMoreThanOneItem) {
        return false
      }
      return true
    },
    {
      message: 'Secondary sites can only be associated with one primary site',
      path: ['site_associations'],
    },
  )
