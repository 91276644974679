import { Box, Button, Stack, Typography, Card, useTheme, FormControlLabel, Switch, IconButton } from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { useState } from 'react'
import UploadDialog from '../UploadDialog'
import TimePointSubmissionStatusChip from '../TimePointSubmissionStatusChip'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { TimepointSubmissionStatus } from '@common/config/api/client'
import ESignDialog from '@features/subject/components/eSignDialog'
import FormRendererDialog from '../FormRendererDialog'
import { useSubmitTimePointEForm } from '@features/subject/hooks/useSubmitTimePointEForm'
import { useSubmitContainerEForm } from '@features/subject/hooks/useSubmitContainerEForm.ts'
import { useParams } from 'react-router-dom'
import NoFilesBlock from '@features/subject/components/NoFilesBlock'
import { DEFAULT_SITE } from '@common/constants/study.ts'

export type TimePointConfigurationProps = {
  name: string
  status?: TimepointSubmissionStatus
  timePointFormId?: string | null
  eFormFields?: string | null
  eFormValues?: string | null
  containersParams: Array<{
    isOptional: boolean
    modality: string
    name: string
    type: 'DICOM' | 'NON_DICOM'
    containerSubmissionId: string
    eFormFields?: string | null
    eFormValues?: string | null
    status?: TimepointSubmissionStatus
    noFilesSubmitted: boolean
  }>
}

export default function TimePointConfiguration({
  name,
  containersParams,
  status,
  timePointFormId,
  eFormFields,
  eFormValues,
}: TimePointConfigurationProps) {
  const theme = useTheme()
  const { studyId, subjectId, timepointSubmissionId } = useParams<{
    studyId: string
    subjectId: string
    timepointSubmissionId: string
  }>()
  const { mutateAsync: submitTimePointEForm } = useSubmitTimePointEForm()
  const { mutateAsync: submitContainerEForm } = useSubmitContainerEForm()
  const [filesUploadDialogVisible, setFilesUploadDialogVisible] = useState(false)
  const [eSignDialogOpen, setESignDialogOpen] = useState(false)
  const [noResultForContainer, setNoResultForContainer] = useState(
    containersParams.reduce<Record<string, boolean>>((acc, item) => {
      acc[item.containerSubmissionId] = false
      return acc
    }, {}),
  )
  const [eFormDialogState, setEFormUploadDialogState] = useState<{
    visible: boolean
    eFormFields?: string | null
    eFormValues?: string | null
    source: 'TIMEPOINT' | 'CONTAINER'
    containerSubmissionId?: string | null
  }>({
    visible: false,
    eFormFields: null,
    eFormValues: null,
    source: 'TIMEPOINT',
  })

  const handleSubmitEForm = async (data: Record<string, string | number | boolean>) => {
    if (eFormDialogState.source === 'TIMEPOINT') {
      await submitTimePointEForm({
        body: {
          form_submission: data,
        },
        path: {
          site_id: DEFAULT_SITE.site_id,
          study_id: studyId!,
          subject_id: subjectId!,
          timepoint_submission_id: timepointSubmissionId!,
        },
      })
    } else {
      await submitContainerEForm({
        body: [
          {
            form_submission: data,
            container_submission_id: eFormDialogState.containerSubmissionId!,
          },
        ],
        path: {
          site_id: DEFAULT_SITE.site_id,
          study_id: studyId!,
          subject_id: subjectId!,
          timepoint_submission_id: timepointSubmissionId!,
        },
      })
    }

    setEFormUploadDialogState({ visible: false, source: 'TIMEPOINT', eFormFields: null })
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h5">{name}</Typography>
          <TimePointSubmissionStatusChip status={status} />
        </Box>
        <Button variant="contained" size="large" color="primary" onClick={() => setESignDialogOpen(true)}>
          Submit
        </Button>
      </Box>
      <Stack gap={2} mt={3}>
        {timePointFormId ? (
          <Card sx={{ p: 3, borderRadius: 4 }} elevation={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="flex-start">
                <CheckCircleOutlineRoundedIcon
                  sx={{ color: eFormValues ? 'success.main' : 'text.disabled', mt: 0.5 }}
                />
                <Box ml={1}>
                  <Typography variant="h6">Clinical information form</Typography>

                  <Typography variant="body1" color="text.secondary">
                    Fill out eForm
                  </Typography>
                </Box>
              </Box>
              {eFormValues ? (
                <IconButton
                  onClick={() =>
                    setEFormUploadDialogState({
                      visible: true,
                      eFormFields,
                      eFormValues,
                      source: 'TIMEPOINT',
                      containerSubmissionId: null,
                    })
                  }
                >
                  <EditRoundedIcon />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() =>
                    setEFormUploadDialogState({
                      visible: true,
                      eFormFields,
                      source: 'TIMEPOINT',
                      containerSubmissionId: null,
                    })
                  }
                >
                  Fill out form
                </Button>
              )}
            </Box>
          </Card>
        ) : null}
        <Card sx={{ p: 3, borderRadius: 4 }} elevation={1}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="flex-start">
              <CheckCircleOutlineRoundedIcon sx={{ color: 'text.disabled', mt: 0.5 }} />
              <Box ml={1}>
                <Typography variant="h6">Exam results</Typography>
                <Typography variant="body1" color="text.secondary">
                  Upload files for completed exams. Please indicate if an exam wasn’t performed.
                </Typography>
              </Box>
            </Box>
            <Button variant="contained" size="medium" color="primary" onClick={() => setFilesUploadDialogVisible(true)}>
              Upload files
            </Button>
          </Box>
          <Stack gap={2} mt={4}>
            {containersParams.map((item, index) => (
              <Box key={index} p={2} borderRadius={3} border="1px solid" borderColor={theme.palette.divider}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Typography variant="h6">{item.name}</Typography>
                    <Box display="flex">
                      <Typography variant="body2" color="text.secondary">
                        {item.type === 'DICOM' ? 'DICOM files' : 'Non-DICOM files'}
                      </Typography>
                      {item.isOptional ? (
                        <>
                          <Typography mx={1} variant="body2" color="text.secondary">
                            •
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Optional
                          </Typography>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                  <FormControlLabel
                    disabled={item.status === 'LOCKED'}
                    checked={item.noFilesSubmitted || noResultForContainer[item.containerSubmissionId]}
                    control={<Switch color="primary" />}
                    label="Not performed for this visit"
                    onChange={(_, checked) =>
                      setNoResultForContainer({ ...noResultForContainer, [item.containerSubmissionId]: checked })
                    }
                    labelPlacement="start"
                  />
                </Box>
                {!item.isOptional && noResultForContainer[item.containerSubmissionId] ? (
                  <NoFilesBlock
                    containerSubmissionId={item.containerSubmissionId}
                    onSign={(containerSubmissionId) =>
                      setNoResultForContainer({ ...noResultForContainer, [containerSubmissionId]: false })
                    }
                  />
                ) : null}
                {item.eFormFields ? (
                  <Box mt={2}>
                    <Typography variant="subtitle2" color="text.secondary" mb={1}>
                      Electronic form
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={1}
                      bgcolor="#F0F4F5"
                      borderRadius={3}
                    >
                      <Box display="flex" alignItems="center">
                        <DescriptionOutlinedIcon sx={{ color: 'text.disabled' }} />
                        <Typography variant="subtitle2" color="text.primary" ml={1}>
                          eForm
                        </Typography>
                      </Box>
                      {item.eFormFields ? (
                        <IconButton
                          size="small"
                          onClick={() =>
                            setEFormUploadDialogState({
                              visible: true,
                              eFormFields: item.eFormFields,
                              eFormValues: item.eFormValues,
                              source: 'CONTAINER',
                              containerSubmissionId: item.containerSubmissionId,
                            })
                          }
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={() =>
                            setEFormUploadDialogState({
                              visible: true,
                              eFormFields: item.eFormFields,
                              source: 'CONTAINER',
                              containerSubmissionId: item.containerSubmissionId,
                            })
                          }
                        >
                          Fill out form
                        </Button>
                      )}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ))}
          </Stack>
        </Card>
        {filesUploadDialogVisible && (
          <UploadDialog
            onSave={() => {}}
            onClose={() => setFilesUploadDialogVisible(false)}
            containersParams={containersParams}
          />
        )}
        {eSignDialogOpen && (
          <ESignDialog payload={{ type: 'timepoint_container_submission' }} onClose={() => setESignDialogOpen(false)} />
        )}
        {eFormDialogState.visible && (
          <FormRendererDialog
            header="Fill eForm"
            onClose={() => setEFormUploadDialogState({ visible: false, source: 'TIMEPOINT', eFormFields: null })}
            fields={eFormDialogState.eFormFields}
            values={eFormDialogState.eFormValues}
            onSubmit={handleSubmitEForm}
          />
        )}
      </Stack>
    </Box>
  )
}
