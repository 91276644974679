import { Badge, Box, IconButton, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import dcmjs from 'dcmjs'
import GroupedFilesBlock from 'src/features/subject/components/GroupedFilesBlock'
import SeriesRow from '@features/subject/components/SeriesRow'
import NonDICOMRow from '@features/subject/components/NonDICOMRow'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import Uppy, { Meta, UppyFile } from '@uppy/core'
import { useEffect, useState } from 'react'

type Series = {
  description: string
  tooltipText: string
  acquisitionDate: string
  seriesNumber: number
  instances: Array<string>
  modality: string
  seriesInstanceUID: string
}

type Study = {
  name: string
  date: string
  series: Array<Series>
}

type NonDicomFile = {
  id: string
  basename?: string
  name?: string
}

type CheckStepProps = {
  uppy: Uppy
  containersParams: Array<{
    modality: string
    containerSubmissionId: string
  }>
}

export default function CheckStep({ uppy, containersParams }: CheckStepProps) {
  const files = uppy.getFiles()
  const [dicomData, setDicomData] = useState<Study[]>()
  const [nonDicomData, setNonDicomData] = useState<NonDicomFile[]>([])

  function getFileNameWithoutExtension(filename?: string): string {
    if (!filename) {
      return ''
    }

    const lastDotIndex = filename.lastIndexOf('.')
    if (lastDotIndex === -1) {
      // No extension found, return the whole name
      return filename
    }

    // Return the name without the extension
    return filename.substring(0, lastDotIndex)
  }

  const groupFilesByStudyId = async (files: UppyFile<Meta, Record<string, never>>[]) => {
    const dicomFiles: Record<string, Study> = {}
    const nonDicomFiles: NonDicomFile[] = []

    for (const file of files) {
      try {
        const arrayBuffer = await file.data.arrayBuffer()
        const byteArray = new Uint8Array(arrayBuffer)

        const isDicom = String.fromCharCode(...byteArray.slice(128, 132)) === 'DICM'
        if (!isDicom) {
          nonDicomFiles.push({ id: file.id, basename: getFileNameWithoutExtension(file.name), name: file.name })
          continue
        }

        const dicomData = dcmjs.data.DicomMessage.readFile(arrayBuffer)
        const dataSet = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomData.dict)

        const studyId = dataSet.StudyID || 'Unknown Study'
        const seriesInstanceUID = dataSet.SeriesInstanceUID || 'Unknown SeriesInstanceUID'
        const sopInstanceUID = dataSet.SOPInstanceUID || null
        const modality = dataSet.Modality || 'Unknown Modality'
        const seriesId = dataSet.SeriesNumber || 'Unknown SeriesNumber'
        const description = dataSet.SeriesDescription || 'Unknown Description'
        const acquisitionDate = dataSet.AcquisitionDate || null
        const studyDate = dataSet.StudyDate || null

        if (!dicomFiles[studyId]) {
          dicomFiles[studyId] = {
            name: studyId,
            date: studyDate ? dayjs(studyDate, 'YYYYMMDD').format('DD/MM/YYYY') : '-',
            series: [],
          }
        }

        const series = dicomFiles[studyId].series.find((s) => s.seriesInstanceUID === seriesInstanceUID)

        if (!series) {
          dicomFiles[studyId].series.push({
            description,
            tooltipText: description || 'Series',
            acquisitionDate: acquisitionDate ? dayjs(acquisitionDate, 'YYYYMMDD').format('DD/MM/YYYY') : '-',
            seriesNumber: seriesId,
            instances: [sopInstanceUID],
            modality,
            seriesInstanceUID,
          })
        } else {
          if (series.instances.some((instance) => instance === sopInstanceUID)) {
            // This feature will be handled in future
          } else {
            series.instances.push(sopInstanceUID)
            uppy.setFileMeta(file.id, {
              ...file.meta,
              container_submission_id: containersParams.find((x) => x.modality === modality)?.containerSubmissionId,
            })
          }
        }
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error)
      }
    }

    return { dicomFiles: Object.values(dicomFiles), nonDicomFiles }
  }

  useEffect(() => {
    ;(async () => {
      const { dicomFiles, nonDicomFiles } = await groupFilesByStudyId(files)
      setDicomData(dicomFiles)
      setNonDicomData(nonDicomFiles)
    })()
  }, [])

  const renderStudyActions = () => {
    return (
      <Box display="flex" gap={1}>
        <IconButton>
          <RemoveRedEyeRoundedIcon sx={{ color: 'text.primary' }} />
        </IconButton>
        <IconButton>
          <DeleteOutlineOutlinedIcon sx={{ color: 'text.primary' }} />
        </IconButton>
      </Box>
    )
  }

  const renderNonDicomActions = () => {
    return (
      <IconButton>
        <DeleteOutlineOutlinedIcon sx={{ color: 'text.primary' }} />
      </IconButton>
    )
  }

  return (
    <Box pb={12}>
      <Typography color="text.primary" variant="h4">
        Studies and adherence criteria check
      </Typography>
      <Typography color="text.secondary" variant="body2" mb={4}>
        Check the studies from uploaded files and enter details for non-DICOM files.
      </Typography>
      <Stack gap={2}>
        {dicomData?.map((item, index) => (
          <GroupedFilesBlock
            title={item.name}
            subtitle={`Study date: ${item.date}`}
            key={index}
            actions={renderStudyActions()}
          >
            <Box pt={4}>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="subtitle2" color="text.secondary" mr={2.25}>
                  Series
                </Typography>
                <Badge variant="standard" color="secondary" badgeContent={item.series.length} />
              </Box>
              <Box borderRadius={3} overflow="hidden">
                {item.series.map((series, subIndex) => (
                  <SeriesRow
                    key={subIndex}
                    isBorderNeeded={subIndex !== item.series.length - 1}
                    description={series.description}
                    acquisitionDate={series.acquisitionDate}
                    seriesNumber={series.seriesNumber}
                    instances={series.instances.length}
                    modality={series.modality}
                    tooltipText={series.tooltipText}
                  />
                ))}
              </Box>
            </Box>
          </GroupedFilesBlock>
        ))}
        <GroupedFilesBlock
          title="Non-DICOM files"
          subtitle="Complete the missing information for each file. Adherence criteria check is not required."
          actions={renderNonDicomActions()}
        >
          <Box pt={4}>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="subtitle2" color="text.secondary" mr={2.25}>
                Files to be converted to DICOM
              </Typography>
              <Badge variant="standard" color="secondary" badgeContent={nonDicomData.length} />
            </Box>
            <Box borderRadius={3} overflow="hidden">
              {nonDicomData.map((item, index) => (
                <NonDICOMRow
                  key={index}
                  isBorderNeeded={index !== nonDicomData.length - 1}
                  seriesNumber={(index + 1).toString()}
                  tooltipText={item.name}
                  seriesDescriptionText={item.basename}
                />
              ))}
            </Box>
          </Box>
        </GroupedFilesBlock>
      </Stack>
    </Box>
  )
}
