import { useParams } from 'react-router-dom'
import { useCompaniesMetadata, useUserDetails } from '@features/user_management/hooks'
import EnhancedTabs from '@common/components/Tab/EnhancedTabs'
import { TabOptionalComponentProvider } from '@common/context/TabOptionalComponentProvider'
import { Typography, Stack, Grid2 as Grid } from '@mui/material'
import { CurrentActiveTabProvider } from '@common/context/CurrentActiveTabProvider'
import ProfileInformation from '@features/user_management/details/components/ProfileInformation'
import { useUpdateUser } from './hooks'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { UserCreateSchema } from '@common/config/api/client'

const formatDate = (date: string) => {
  return new Date(date)
    .toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })
    .replace(/\./g, '/')
}

export default function AccountManagementProfile() {
  const { userId, companyId, studyId } = useParams()
  const updateUserMutation = useUpdateUser()

  const { data: user, isPending: isUserDetailsPending } = useUserDetails({
    companyId: companyId ?? '',
    userId: userId ?? '',
  })
  const { data: companiesMetadata, isPending: isPendingCompaniesMetadata } = useCompaniesMetadata()

  const handleUpdateUser = async (userData: UserCreateSchema) => {
    if (!studyId || !userId) return
    try {
      await updateUserMutation.mutateAsync({
        studyId,
        userId,
        userData,
      })
      toast(<Toast message="User updated successfully" variant="success" />)
    } catch (error) {
      console.error('Failed to update user:', error)
      toast(<Toast message="Failed to update user. Please try again." variant="error" />)
    }
  }

  const isLoading = isUserDetailsPending || isPendingCompaniesMetadata
  if (isLoading) {
    return <div>Loading...</div>
  }

  const tabs = {
    profile: {
      label: 'Profile information',
      content: <ProfileInformation user={user} companiesMetadata={companiesMetadata} onUpdateUser={handleUpdateUser} />,
    },
    settings: {
      label: 'Settings',
      content: <></>,
    },
  }

  return (
    <>
      <Grid container columnSpacing={3} sx={{ marginTop: 4 }}>
        <Grid size={12}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h4">{user?.user_name}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2" color="text.secondary">
                User created: {user?.created_at && formatDate(user.created_at)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                -{' '}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Created by: {user?.created_by ?? '-'}
              </Typography>
            </Stack>
            <CurrentActiveTabProvider>
              <TabOptionalComponentProvider>
                <EnhancedTabs tabs={tabs} />
              </TabOptionalComponentProvider>
            </CurrentActiveTabProvider>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
