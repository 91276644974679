import { TableBody, TableCell, TableRow, Paper, IconButton, Box, Stack, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import TableToolbar from './TableToolbar'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import ActionMenu from './ActionMenu'
import Link from '@common/components/Link'
import { PaginatedResponse_StudyUserReadSchema_, StudyUserReadSchema } from '@common/config/api/client'
import { HeadCell, Order } from '@common/components/Table/table.types'
import useUserTable from '../../hooks/useUserTable'
import { MouseEvent } from 'react'
import { useParams } from 'react-router-dom'

interface Props {
  usersList: PaginatedResponse_StudyUserReadSchema_ | undefined
  onSearch: (value: string) => void
  onAddUser: () => void
  onDeleteUser: (companyId: string, userId: string) => void
  searchValue?: string
  page: number
  orderBy: keyof StudyUserReadSchema
  order: Order
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
}

type UserTableSchema = StudyUserReadSchema & {
  actions: never
}

const headCells: readonly HeadCell<UserTableSchema>[] = [
  { id: 'user_name', label: 'User name', disablePadding: false },
  { id: 'user_email', label: 'Email address', disablePadding: false },
  { id: 'role_name', label: 'Role', disablePadding: false },
  { id: 'is_verified', label: 'Verified', disablePadding: false },
  { id: 'created_by', label: 'Created by', disablePadding: false },
  { id: 'actions', label: '', disablePadding: false },
] as const

function EmptyUserTable() {
  return (
    <Stack spacing={1} alignItems="center" sx={{ py: 8 }}>
      <Typography variant="h6">No matching user found</Typography>
      <Typography variant="body2" color="text.secondary">
        Please ensure the user's name is correct, or try a different one
      </Typography>
    </Stack>
  )
}

export default function UserTable({
  usersList,
  onSearch,
  searchValue,
  onAddUser,
  onDeleteUser,
  page,
  order,
  orderBy,
  onChangePage,
  onRequestSort,
  onChangeRowsPerPage,
}: Props) {
  const users = usersList?.items ?? []
  const { studyId } = useParams()
  const { menuAnchorEl, handleOpenMenu, handleCloseMenu, handleEditUser, handleDeleteUser } = useUserTable(
    users,
    onDeleteUser,
  )

  return (
    <Box component="section">
      <TableToolbar searchValue={searchValue} onSearch={onSearch} onAddUser={onAddUser} />
      <Paper sx={{ p: 3 }}>
        <EnhancedNavTabs routes={[]} />
        <EnhancedTable<UserTableSchema>
          rows={users}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          selected={[]}
          rowsPerPageOptions={[2, 10, 25, 50]}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onRequestSort={onRequestSort}
          next_cursor={usersList?.pagination.next_cursor}
          previous_cursor={usersList?.pagination.previous_cursor}
          rowsPerPage={usersList?.pagination.limit ?? 50}
        >
          <TableBody>
            {users.length > 0 ? (
              users.map((user) => (
                <TableRow hover key={user.user_id}>
                  <TableCell>
                    <Link to={`/studies/${studyId}/account-management/${user.company_id}/${user.user_id}`}>
                      {user.user_name}
                    </Link>
                  </TableCell>
                  <TableCell>{user.user_email}</TableCell>
                  <TableCell>{user.role_name}</TableCell>
                  <TableCell>{user.is_verified ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{user.created_by}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={(e) => handleOpenMenu(e, user.user_id)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <EmptyUserTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
        <ActionMenu
          anchorEl={menuAnchorEl}
          onClose={handleCloseMenu}
          onEdit={handleEditUser}
          onDelete={handleDeleteUser}
        />
      </Paper>
    </Box>
  )
}
