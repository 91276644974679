import { useMutation } from '@tanstack/react-query'
import {
  addStudyUserStudiesStudyIdUsersPost,
  AddStudyUserStudiesStudyIdUsersPostResponse,
  HTTPValidationError,
  UserCreateSchema,
} from '@common/config/api/client'

import { queryClient } from '@common/config/api/queryClient'
import { AxiosError } from 'axios'
import queryKeyFactory from '../queryKeyFactory'

interface Params {
  studyId: string
  userData: UserCreateSchema
}

export default function useAddUser() {
  return useMutation<AddStudyUserStudiesStudyIdUsersPostResponse, AxiosError<HTTPValidationError>, Params>({
    mutationFn: async ({ studyId, userData }) => {
      const response = await addStudyUserStudiesStudyIdUsersPost({
        path: {
          study_id: studyId,
        },
        body: {
          role_id: userData.role_id,
          company_id: userData.company_id,
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
        },
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeyFactory.allUsers() })
    },
  })
}
