import {
  HTTPValidationError,
  DeleteQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdDeleteResponse,
  DeleteQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdDeleteData,
  deleteQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdDelete,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useDeleteQcStudyDefinitionMutation = (): UseMutationResult<
  DeleteQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdDeleteResponse,
  AxiosError<HTTPValidationError>,
  DeleteQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdDeleteData
> => {
  return useMutation<
    DeleteQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdDeleteResponse,
    AxiosError<HTTPValidationError>,
    DeleteQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdDeleteData
  >({
    mutationFn: async (query) => {
      const response =
        await deleteQcStudyDefinitionQcTasksQcTaskIdSubmissionsQcTaskSubmissionIdStudyDefinitionsStudyDefinitionIdDelete(
          {
            path: query.path,
            throwOnError: true,
          },
        )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTask'] })
    },
  })
}
