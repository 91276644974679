import { Typography, Stack, Grid2 as Grid, ButtonGroup, Button } from '@mui/material'
import { useAccountManagement } from './hooks'
import { useParams } from 'react-router-dom'

import UserTable from './management/components/UserTable'
import { UserSchema } from '@common/config/api/client'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import AddUserMenu from './management/components/AddUserMenu'
import AssignUserModal from './management/components/AssignUserModal'
import AddUserModal from './management/components/AddUserModal'

export default function AccountManagement() {
  const { studyId } = useParams()
  const {
    page,
    addMenuAnchor,
    isAddModalOpen,
    isAssignModalOpen,
    usersList,
    companiesMetadata,
    userListParams,
    isLoading,
    handleOpenUserMenu,
    handleCloseUserMenu,
    handleUserTypeSelect,
    handleAddUser,
    handleAssignUser,
    handleDeleteUser,
    handleSearch,
    handlePageChange,
    handleRequestSort,
    handleRowsPerPageChange,
    setIsAddModalOpen,
    setIsAssignModalOpen,
  } = useAccountManagement(studyId!)

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Grid container columnSpacing={3} sx={{ marginTop: 4 }}>
      <Grid size={12}>
        <Stack spacing={8}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">User management</Typography>
            <ButtonGroup variant="contained">
              <Button onClick={handleOpenUserMenu}>Add new</Button>
              <Button sx={{ px: 0.75 }} onClick={handleOpenUserMenu}>
                <KeyboardArrowDownRoundedIcon />
              </Button>
            </ButtonGroup>
          </Stack>
          <UserTable
            usersList={usersList}
            onAddUser={() => setIsAddModalOpen(true)}
            onDeleteUser={handleDeleteUser}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onSearch={handleSearch}
            onRequestSort={handleRequestSort}
            searchValue={userListParams.search ?? ''}
            orderBy={userListParams.order_by_field as keyof UserSchema}
            order={userListParams.asc_order ? 'asc' : 'desc'}
          />
        </Stack>
      </Grid>
      <AddUserMenu anchorEl={addMenuAnchor} onClose={handleCloseUserMenu} onUserTypeSelect={handleUserTypeSelect} />
      <AddUserModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddUser}
        companiesMetadata={companiesMetadata}
      />
      <AssignUserModal
        open={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        onSubmit={handleAssignUser}
        companiesMetadata={companiesMetadata}
      />
    </Grid>
  )
}
