import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { PopupState } from 'material-ui-popup-state/hooks'
import { toast } from 'sonner'
import Toast from './Toast'
import { AxiosError } from 'axios'
import { useReopenTasksMutation } from '@qc_task_list_view/hooks/useReopenTasksMutation'
import ArrowTooltips from '@common/components/Tooltip'
import { TextField } from '@common/components/Form/TextField'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { HTTPValidationError } from '@common/config/api/client'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 1,
}

const ReopenValidationMessage = 'Provide a reason why you need to re-open the task'

const ReopenValidationSchema = z.object({
  reason: z.string().min(1, ReopenValidationMessage),
})

export default function ReopenModal({
  task_id,
  pseudo_id,
  study_id,
  popupState,
}: {
  task_id: string
  pseudo_id: string
  study_id: string
  popupState: PopupState
}) {
  const {
    control,
    watch,
    formState: { isValid },
    setError,
  } = useForm({ mode: 'onChange', defaultValues: { reason: '' }, resolver: zodResolver(ReopenValidationSchema) })
  const reason = watch('reason')
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    popupState.close()
  }
  const { mutateAsync: reopenTask } = useReopenTasksMutation()

  const handleReopenTask = async (task_ids: string[], study_id: string, reason: string) => {
    if (isValid) {
      await reopenTask({
        reason: reason,
        study_qc_task_ids: task_ids,
        study_id: study_id,
      })
        .then(() => {
          toast(<Toast message="Your request was sent successfully" variant="success" data-testid="success-toast" />)
          handleClose()
        })
        .catch((error: AxiosError<HTTPValidationError>) => {
          const errorMessage = Array.isArray(error.response?.data?.detail)
            ? error.response?.data?.detail.map((err) => err.msg).join(', ')
            : error.response?.data?.detail || error.message
          toast(<Toast message={errorMessage} variant="error" data-testid="error-toast" />)
        })
    } else {
      setError('reason', { message: ReopenValidationMessage })
    }
  }

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          gap: 1.5,
          alignItems: 'center',
        }}
        data-testid="reopen-task-button"
        tabIndex={0}
        aria-label="Reopen Task Button"
      >
        <Typography variant="body1">Reopen</Typography>
        <ArrowTooltips
          message={'Reopening the task will maintain all existing data, and allow for adding new or editing of data.'}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        data-testid="reopen-modal"
        aria-labelledby="reopen-modal-title"
        aria-describedby="reopen-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              alignSelf: 'stretch',
              width: 600,
            }}
          >
            <Typography variant="h6" color="text.primary" id="reopen-modal-title">
              {`Re-open task №${pseudo_id}?`}
            </Typography>
            <Typography variant="body1" color="text.secondary" id="reopen-modal-description">
              Briefly explain why you want to re-open this task.
            </Typography>
          </Box>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              padding: 2,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              name="reason"
              id="outlined-multiline-static"
              label="Provide a reason"
              control={control}
              multiline
              rows={6}
              fullWidth
              onKeyDown={(e) => e.stopPropagation()}
              data-testid="reason-textfield"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              alignSelf: 'stretch',
              padding: 2,
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              data-testid="cancel-reopen-task-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
              }}
              onClick={() => handleReopenTask([task_id], study_id, reason)}
              data-testid="confirm-reopen-task-button"
            >
              Re-open task
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
