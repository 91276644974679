import { AppBar, Box, Button, Dialog, DialogContent, Grid2, Stack, Toolbar, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { HorizontalLinearStepper } from '@common/components/Stepper'
import AddFilesStep from '../AddFilesStep'
import CheckStep from '../CheckStep'
import UploadStep from '../UploadStep'
import { ArrowBackRounded } from '@mui/icons-material'
import Uppy from '@uppy/core'
import GoldenRetriever from '@uppy/golden-retriever'
import Tus from '@uppy/tus'
import { useStepper } from '@common/context/StepperContext'
import { ALLOWED_META_FIELDS } from '@features/subject/utils'
import { SESSION_STORAGE_KEY } from '@common/constants/api'

type UploadDialogProps = {
  onClose: () => void
  onSave: (data: unknown) => void
  containersParams: Array<{
    modality: string
    containerSubmissionId: string
  }>
}

function createUppy() {
  const userData = sessionStorage.getItem(SESSION_STORAGE_KEY)

  if (!userData) {
    throw new Error('No user data found in Session Storage')
  }

  const user = JSON.parse(userData)
  const access_token = user['access_token']

  return new Uppy()
    .use(Tus, {
      endpoint: `${import.meta.env.VITE_API_URL}/timepoint/files`,
      allowedMetaFields: ALLOWED_META_FIELDS,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .use(GoldenRetriever, { serviceWorker: true })
}

export default function UploadDialog({ onClose, onSave, containersParams }: UploadDialogProps) {
  const { steps, activeStep, setSteps, setActiveStep } = useStepper()
  const [uppy] = useState(createUppy)
  const [mode, setMode] = useState<'STEPPER' | 'UPLOAD'>('STEPPER')

  const handleSave = () => {
    onSave({})
    onClose()
    setActiveStep(0)
  }

  useEffect(() => {
    setSteps([{ label: 'Add and review files' }, { label: 'Studies and adherence criteria check' }])
  }, [])

  const handleContinueClick = () => {
    if (activeStep === 0) {
      setActiveStep(1)
    } else {
      setMode('UPLOAD')
    }
  }

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={() => null}
      aria-labelledby="upload-dialog-title"
      aria-describedby="upload-dialog-description"
    >
      <AppBar position="static" sx={{ bgcolor: 'white', boxShadow: 2, zIndex: 10 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Stack>
            <Typography id="upload-dialog-title" color="text.primary" variant="subtitle2">
              Baseline 1
            </Typography>
            <Typography id="upload-dialog-description" color="text.secondary" variant="body2">
              Subject 123098564
            </Typography>
          </Stack>
          <Button
            onClick={handleSave}
            color="secondary"
            variant="contained"
            size="large"
            aria-label="Save and close dialog"
          >
            Save and close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ p: 0, bgcolor: '#F0F4F5' }}>
        <Grid2 container height={1}>
          <Grid2 size={8} offset={2} pt={4}>
            {activeStep === 0 && mode === 'STEPPER' && <AddFilesStep uppy={uppy} />}
            {activeStep === 1 && mode === 'STEPPER' && <CheckStep uppy={uppy} containersParams={containersParams} />}
            {mode === 'UPLOAD' && <UploadStep uppy={uppy} />}
          </Grid2>
        </Grid2>
      </DialogContent>
      {mode !== 'UPLOAD' && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: '100%',
            zIndex: 10,
            boxShadow:
              '0px -16px 16px -8px rgba(14, 63, 126, 0.03), 0px -8px 8px -4px rgba(14, 63, 126, 0.02), 0px -4px 4px -2px rgba(42, 51, 69, 0.02), 0px -2px 2px -1px rgba(42, 51, 69, 0.02), 0px -1px 1px -0.5px rgba(42, 51, 69, 0.02), 0px 0px 0px 1px rgba(14, 63, 126, 0.02)',
          }}
        >
          <AppBar position="static" sx={{ bgcolor: 'white', boxShadow: 2 }}>
            <Toolbar sx={{ justifyContent: 'space-between', columnGap: 40 }}>
              <Box width={150}>
                {activeStep !== 0 && (
                  <Button
                    size="large"
                    color="secondary"
                    variant="text"
                    startIcon={<ArrowBackRounded />}
                    onClick={() => setActiveStep(activeStep - 1)}
                    aria-label="Go to previous step"
                  >
                    Previous step
                  </Button>
                )}
              </Box>

              <Box sx={{ flex: 1 }}>
                <HorizontalLinearStepper
                  steps={steps}
                  activeStep={activeStep}
                  showStepLabels
                  aria-label="Upload process steps"
                />
              </Box>
              <Button size="large" color="primary" variant="contained" onClick={handleContinueClick}>
                Continue
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
      )}
    </Dialog>
  )
}
