import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Stack,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItem,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  HomeRounded,
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowRightRounded,
  ManageAccountsRounded,
  NightsStay,
  SpaceDashboardRounded,
} from '@mui/icons-material'
import Logo from '/images/logo-dark.svg'
import { ReactNode, useState } from 'react'
import { ListItemButtonProps } from '@mui/material'
import { getActive, isParentActive } from '@common/utils/urlUtils'
import { ROUTES } from 'src/routes'
import { useSidebar } from '@common/context/SidebarContext'
import { Switch } from '../Form/Switch'
import { useForm } from 'react-hook-form'
import {
  CompanyManagementPermission,
  FormManagementPermission,
  Permission,
  QueryTemplateManagementPermission,
  SeriesLabelManagementPermission,
  TagDeidManagementPermission,
} from '@auth/permissionsEnum'
import { useGlobalPermissions } from '@auth/hooks/useGlobalPermissions'

interface MenuItem {
  label: string
  path: string | null
  icon: ReactNode
  subItems?: MenuItem[]
  permission?: Permission
}

export interface ListItemStyleProps extends ListItemButtonProps {
  active: boolean
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ListItemStyleProps>(({ active, theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(1),
  paddingBottom: theme.spacing(1.25),
  paddingTop: theme.spacing(1.25),
  display: 'flex',
  justifyContent: 'center',
  ...(active && {
    backgroundColor: theme.palette.secondary.main,
  }),
}))

export const SubListItemStyle = styled(ListItemStyle)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  marginBottom: theme.spacing(0.5),
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
}))

export const ListIconStyle = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ListItemStyleProps>(({ active, theme }) => ({
  minWidth: 0,
  color: active ? theme.palette.text.secondary : theme.palette.secondary.dark,
}))

export const ListTextStyle = styled(ListItemText)(({ theme }) => ({
  whiteSpace: 'nowrap',
  marginTop: 0,
  marginBottom: 0,
  marginLeft: theme.spacing(1),
}))

export const SIDEBAR_WIDTH = 240
export const COLLAPSED_SIDEBAR_WIDTH = 64

const menuItems: MenuItem[] = [
  {
    label: ROUTES.HOME.label,
    path: ROUTES.HOME.path,
    icon: <HomeRounded />,
  },
  {
    label: 'Management Hub',
    path: null,
    icon: <SpaceDashboardRounded />,
    subItems: [
      {
        label: ROUTES.FORM_MANAGEMENT.label,
        path: ROUTES.FORM_MANAGEMENT.path,
        icon: null,
        permission: FormManagementPermission.ALL,
      },
      {
        label: ROUTES.QUERY_TEMPLATES_MANAGEMENT.label,
        path: ROUTES.QUERY_TEMPLATES_MANAGEMENT.path,
        icon: null,
        permission: QueryTemplateManagementPermission.ALL,
      },
      {
        label: ROUTES.SERIES_LABEL_MANAGEMENT.label,
        path: ROUTES.SERIES_LABEL_MANAGEMENT.path,
        icon: null,
        permission: SeriesLabelManagementPermission.ALL,
      },
      {
        label: ROUTES.TAG_DEID_MANAGEMENT.label,
        path: ROUTES.TAG_DEID_MANAGEMENT.path,
        icon: null,
        permission: TagDeidManagementPermission.ALL,
      },
    ],
  },
  {
    label: 'Account Manager',
    path: null,
    icon: <ManageAccountsRounded />,
    subItems: [
      {
        label: ROUTES.USERS_MANAGEMENT.label,
        path: ROUTES.USERS_MANAGEMENT.path,
        icon: null,
        permission: CompanyManagementPermission.ALL,
      },
      {
        label: ROUTES.COMPANIES_MANAGEMENT.label,
        path: ROUTES.COMPANIES_MANAGEMENT.path,
        icon: null,
        permission: CompanyManagementPermission.ALL,
      },
    ],
  },
]

export function SideBar() {
  const { pathname } = useLocation()
  const { isCollapsed, setIsCollapsed, openMenus, setOpenMenus } = useSidebar()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [activeMenuItem, setActiveMenuItem] = useState<MenuItem | null>(null)
  const { control } = useForm({
    defaultValues: {
      enable_dark_mode: false,
    },
  })
  const { hasPermission } = useGlobalPermissions()

  const filterMenuItems = (items: MenuItem[]): MenuItem[] => {
    return items
      .map((item) => {
        if (item.subItems) {
          const filteredSubItems = filterMenuItems(item.subItems)
          return {
            ...item,
            subItems: filteredSubItems,
          }
        }

        return !item.permission || hasPermission(item.permission) ? item : null
      })
      .filter((item): item is MenuItem => item !== null && (!item.subItems || item.subItems.length > 0))
  }

  const filteredMenuItems = filterMenuItems(menuItems)

  const handleItemClick = (item: MenuItem, event: React.MouseEvent<HTMLElement>) => {
    if (item.subItems) {
      if (isCollapsed) {
        setAnchorEl(event.currentTarget)
        setActiveMenuItem(item)
      } else {
        setActiveMenuItem(item)
        setOpenMenus({
          ...openMenus,
          [item.label]: !openMenus[item.label],
        })
      }
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setActiveMenuItem(null)
  }

  const renderMenuItem = (item: MenuItem, isSubItem = false) => {
    const active = item.subItems ? isParentActive(item.subItems, pathname) : getActive(item.path, pathname)
    const hasSubItems = item.subItems && item.subItems.length > 0
    const isOpen = openMenus[item.label]

    return (
      <Box key={item.path || item.label}>
        {hasSubItems ? (
          <Tooltip title={isCollapsed ? item.label : ''} placement="right" arrow>
            <ListItemStyle active={isCollapsed && active} onClick={(e) => handleItemClick(item, e)}>
              <ListIconStyle active={isCollapsed && active}>{item.icon}</ListIconStyle>
              {!isCollapsed && (
                <>
                  <ListTextStyle
                    primary={item.label}
                    primaryTypographyProps={{ sx: { fontWeight: 500, fontSize: 14 } }}
                  />
                  {isOpen ? (
                    <ExpandLess sx={{ color: 'text-secondary' }} />
                  ) : (
                    <ExpandMore sx={{ color: 'text-secondary' }} />
                  )}
                </>
              )}
            </ListItemStyle>
          </Tooltip>
        ) : (
          <RouterLink to={item.path || ''} style={{ textDecoration: 'none' }}>
            <Tooltip title={isCollapsed ? item.label : ''} placement="right" arrow>
              {isSubItem ? (
                <SubListItemStyle active={active}>
                  <ListIconStyle active={active}>{item.icon}</ListIconStyle>
                  {!isCollapsed && (
                    <ListTextStyle primary={item.label} primaryTypographyProps={{ sx: { fontSize: 14 } }} />
                  )}
                </SubListItemStyle>
              ) : (
                <ListItemStyle active={active}>
                  <ListIconStyle active={active}>{item.icon}</ListIconStyle>
                  {!isCollapsed && (
                    <ListTextStyle
                      primary={item.label}
                      primaryTypographyProps={{ sx: { fontWeight: 500, fontSize: 14 } }}
                    />
                  )}
                </ListItemStyle>
              )}
            </Tooltip>
          </RouterLink>
        )}

        {hasSubItems && !isCollapsed && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems!.map((subItem) => renderMenuItem(subItem, true))}
            </List>
          </Collapse>
        )}
      </Box>
    )
  }

  return (
    <>
      <Drawer
        open
        variant="persistent"
        PaperProps={{
          sx: {
            overflowX: 'hidden',
            width: isCollapsed ? COLLAPSED_SIDEBAR_WIDTH : SIDEBAR_WIDTH,
            borderRightStyle: 'none',
            bgcolor: 'white',
            paddingX: 1.25,
            height: '100%',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.standard,
              }),
          },
        }}
      >
        <Box position="relative" zIndex={1} height="100%">
          <Stack direction="row" justifyContent={isCollapsed ? 'center' : 'space-between'} py={2} px={1} mb={2}>
            {!isCollapsed && <img src={Logo} alt="logo" width={55} />}
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              {isCollapsed ? <KeyboardDoubleArrowRightRounded /> : <KeyboardDoubleArrowLeftRounded />}
            </IconButton>
          </Stack>

          <List sx={{ position: 'unset' }}>
            {filteredMenuItems.map((item) => renderMenuItem(item))}
            <Stack
              direction={isCollapsed ? 'column' : 'row'}
              justifyContent="space-between"
              sx={{
                width: '100%',
                position: 'absolute',
                bottom: 16,
              }}
            >
              <ListItem sx={{ px: 1, py: 1.5 }}>
                <ListIconStyle active={false}>
                  <NightsStay />
                </ListIconStyle>
                {!isCollapsed && (
                  <ListTextStyle primary="Dark mode" primaryTypographyProps={{ sx: { fontWeight: 500 } }} />
                )}
              </ListItem>
              <Tooltip title={isCollapsed ? 'Dark mode' : ''} placement="right" arrow>
                <Switch
                  control={control}
                  name="enable_dark_mode"
                  label=""
                  sx={{ marginRight: 0, paddingX: 2 }}
                  MUISwitchProps={
                    isCollapsed
                      ? {
                          sx: {
                            paddingX: 2,
                            position: 'relative',
                            right: 10,
                          },
                        }
                      : {}
                  }
                />
              </Tooltip>
            </Stack>
          </List>
        </Box>
      </Drawer>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem sx={{ fontWeight: 'medium' }}>{activeMenuItem?.label}</MenuItem>
        {activeMenuItem?.subItems?.map((subItem) => (
          <MenuItem
            key={subItem.path}
            onClick={handleClose}
            component={RouterLink}
            to={subItem.path || ''}
            sx={{ paddingLeft: 3 }}
          >
            {subItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
