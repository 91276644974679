import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'sonner'
import { AxiosError } from 'axios'
import { queryClient } from '@common/config/api/queryClient'
import Toast from '@common/components/Toast'
import { CompanyTypeFilter, HTTPValidationError, PaginatedResponse_UserSchema_ } from '@common/config/api/client'
import { UserFormData, UserListParams } from '../types'
import { useAddUser, useDeleteUser, useUsersList, useCompaniesMetadata } from '.'

export default function useUserManagementHandlers() {
  const location = useLocation()
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [userListParams, setUserListParams] = useState<UserListParams>({
    company_type: 'all',
    search: undefined,
    limit: 50,
    next_cursor: undefined,
    previous_cursor: undefined,
    order_by_field: 'user_name',
    asc_order: true,
  })

  const { data: usersList, isPending: isUsersListPending } = useUsersList(userListParams)
  const { data: companiesMetadata, isPending: isCompaniesMetadataPending } = useCompaniesMetadata()
  const { mutate: addUser } = useAddUser()
  const { mutate: deleteUser } = useDeleteUser()

  const isLoading = isUsersListPending || isCompaniesMetadataPending

  const handlePageChange = (
    usersList: PaginatedResponse_UserSchema_ | undefined,
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const isNextPage = newPage > page
    const cursor = isNextPage ? usersList?.pagination.next_cursor : usersList?.pagination.previous_cursor

    if (cursor) {
      setUserListParams((prev) => ({
        ...prev,
        next_cursor: isNextPage ? cursor : null,
        previous_cursor: isNextPage ? null : cursor,
      }))
      setPage(isNextPage ? page + 1 : page - 1)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserListParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handleSearch = (value: string) => {
    setUserListParams((prev) => ({
      ...prev,
      search: value || undefined,
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = userListParams.order_by_field === property && userListParams.asc_order
    setUserListParams((prev) => ({
      ...prev,
      order_by_field: property,
      asc_order: !isAsc,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleAddUser = (userData: UserFormData) => {
    addUser(
      { companyId: userData.company_id, userData },
      {
        onSuccess: () => {
          toast(<Toast message="User added successfully" variant="success" />)
          setIsAddModalOpen(false)
        },
        onError: (error: AxiosError<HTTPValidationError>) => {
          const errorMessage =
            typeof error.response?.data?.detail === 'string'
              ? error.response.data.detail
              : error.message || 'Failed to add user'
          toast(<Toast message={errorMessage} variant="error" />)
        },
      },
    )
    setIsAddModalOpen(false)
  }

  const handleDeleteUser = (companyId: string, userId: string) => {
    deleteUser(
      { companyId, userId },
      {
        onSuccess: () => {
          toast(<Toast message="User deleted successfully" variant="success" />)
          queryClient.invalidateQueries({ queryKey: ['users'] })
        },
        onError: (error) => {
          toast(<Toast message={error.message || 'Failed to delete user'} variant="error" />)
        },
      },
    )
  }
  const handleCompanyTypeChange = useCallback(() => {
    if (companiesMetadata?.companies) {
      const hash = location.hash.slice(1)
      const companyType = hash || 'all'

      setUserListParams((prev) => ({
        ...prev,
        company_type: companyType as CompanyTypeFilter,
        next_cursor: null,
        previous_cursor: null,
      }))
      setPage(0)
    }
  }, [companiesMetadata?.companies, location.hash, setUserListParams, setPage])

  return {
    isLoading,
    isAddModalOpen,
    usersList,
    companiesMetadata,
    page,
    userListParams,
    setIsAddModalOpen,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddUser,
    handleDeleteUser,
    handleCompanyTypeChange,
  }
}
