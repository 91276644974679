import {
  resetQcTasksResetPatch,
  ResetQcTasksResetPatchResponse,
  Body_reset_qc_tasks_reset_patch,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useResetTasksMutation = (): UseMutationResult<
  ResetQcTasksResetPatchResponse,
  AxiosError<HTTPValidationError>,
  Body_reset_qc_tasks_reset_patch
> => {
  return useMutation<ResetQcTasksResetPatchResponse, AxiosError<HTTPValidationError>, Body_reset_qc_tasks_reset_patch>({
    mutationFn: async (body) => {
      const response = await resetQcTasksResetPatch({
        body: body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTasksList'] })
    },
  })
}
