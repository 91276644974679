import { useMemo } from 'react'
import { TableBody, TableRow, TableCell, useTheme } from '@mui/material'
import TaskTypeChip from './TaskTypeChip'
import { EnhancedTableBodyProps } from '../qc_task_list.types'
import TaskStateChip from './TaskStateChip'
import QueryStatusChip from './QueryStatusChip'
import { formatTaskDate, getComparator } from '../utils'
import { useNavigate, useParams } from 'react-router-dom'
import { generatePathWithParams } from '@common/utils/urlUtils'
import { ROUTES } from 'src/routes'
import { getPrimarySitePseudoId } from '@common/utils/getPrimarySitePseudoID'
import MyTasksPopupMenuList from './MyTasksPopupMenuList'

export default function EnhancedTableBodyMyTasks({ rows, order, orderBy }: EnhancedTableBodyProps) {
  const visibleRows = useMemo(() => {
    const comparator =
      orderBy === 'sites.site_pseudo_id'
        ? getComparator(order, orderBy, (item) => getPrimarySitePseudoId(item.sites))
        : getComparator(order, orderBy)
    return [...rows].sort(comparator)
  }, [order, orderBy, rows])

  const navigate = useNavigate()
  const { studyId } = useParams()
  const theme = useTheme()

  const handleViewClick = (itemId: string) => {
    const path = generatePathWithParams(ROUTES.QC_TASK_DETAILS.path, { studyId: studyId!, qcTaskId: itemId })
    navigate(path)
  }

  return (
    <TableBody>
      {visibleRows.map((row) => {
        return (
          <TableRow
            tabIndex={-1}
            key={row.task_id}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              cursor: 'pointer',
            }}
            hover
            onClick={() => handleViewClick(row.task_id)}
          >
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="site-pseudo-id-cell">
              {getPrimarySitePseudoId(row.sites)}
            </TableCell>
            <TableCell align="left" data-testid="subject-pseudo-id-cell">
              {getPrimarySitePseudoId(row.sites)} - {row.subject.subject_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="timepoint-name-cell">
              {row.timepoint_requirement.name}
            </TableCell>
            <TableCell align="left" data-testid="task-type-cell">
              <TaskTypeChip taskType={row.task_type} />
            </TableCell>
            <TableCell align="left" data-testid="task-state-cell">
              <TaskStateChip taskState={row.task_state} />
            </TableCell>
            <TableCell align="left" data-testid="query-status-cell">
              <QueryStatusChip queryStatus={row.query_status} />
            </TableCell>
            <TableCell align="left" data-testid="create-date-cell">
              {formatTaskDate(row.create_date)}
            </TableCell>
            <TableCell align="right" data-testid="exclude-modal-cell">
              <MyTasksPopupMenuList pseudo_id={row.task_pseudo_id} task_id={row.task_id} study_id={studyId!} />
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
