import { IconButton, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import { ContentCopyRounded, DeleteOutlineRounded, MoreVertRounded } from '@mui/icons-material'
import MergeModal from './MergeModal'
import { QcStudyDefinitionCreateFormValues } from './StudyAndSeriesDefinitionValidationSchema'
import { Control } from 'react-hook-form'

export default function SeriesDefinitionPopupMenuList({
  index,
  control,
  newSeriesDescriptions,
  handleDelete,
  handleDuplicate,
  handleMerge,
}: {
  index: number
  control: Control<QcStudyDefinitionCreateFormValues>
  newSeriesDescriptions: { value: string; label: string }[]
  handleDelete: () => void
  handleDuplicate: () => void
  handleMerge: (new_series_description: string, index?: number) => void
}) {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <IconButton {...bindTrigger(popupState)}>
            <MoreVertRounded
              sx={{
                color: 'text.secondary',
                width: 20,
                height: 20,
              }}
            />
          </IconButton>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingY: 0.5,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: 0.5,
                }}
                data-testid="popup-menu-list-item-reopen"
                onClick={handleDuplicate}
              >
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <ContentCopyRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Duplicate</Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: 0.5,
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <MergeModal
                  control={control}
                  index={index}
                  popupState={popupState}
                  handleMerge={handleMerge}
                  newSeriesDescriptions={newSeriesDescriptions}
                />
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: 0.5,
                }}
                data-testid="popup-menu-list-item-reset"
                onClick={handleDelete}
              >
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <DeleteOutlineRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Delete</Typography>
                </Stack>
              </MenuItem>
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
