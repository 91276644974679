import { z } from 'zod'

function parseRanges(str: string): Array<{ start: number; end: number }> {
  const tokens = str.split('-').map((t) => t.trim())

  if (tokens.length % 2 !== 0) {
    throw new Error('Expected an even number of dash-separated parts.')
  }

  const ranges = []
  for (let i = 0; i < tokens.length; i += 2) {
    const start = Number(tokens[i])
    const end = Number(tokens[i + 1])

    if (Number.isNaN(start) || Number.isNaN(end) || start <= 0 || end <= 0 || start >= end) {
      throw new Error('One of the numbers is invalid.')
    }

    ranges.push({ start, end })
  }

  return ranges
}

const singleRangeSchema = z
  .object({
    start: z.number(),
    end: z.number(),
  })
  .refine(({ start, end }) => start <= end, {
    message: 'Start must not exceed end.',
  })

const qcSeriesDefinitionCreateSchema = z
  .object({
    series_label_id: z.string().min(1, 'New series description is required.'),
    series_metadata_id: z.string().min(1, 'Source series description is required.'),
    submission_series_id: z.string(),
    order: z.number().default(1),
    max_instance_number: z.number().min(1, {
      message: 'max_instance_number must be at least 1.',
    }),
    instance_number_ranges: z
      .string()
      .transform((val, ctx) => {
        try {
          return parseRanges(val)
        } catch (error: unknown) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: error instanceof Error ? error.message : 'Unable to parse ranges.',
          })
          return []
        }
      })
      .pipe(
        z
          .array(singleRangeSchema)
          .min(1, 'Number needed')
          .refine(
            (ranges) => {
              for (let i = 0; i < ranges.length - 1; i++) {
                if (ranges[i].end >= ranges[i + 1].start) {
                  return false
                }
              }
              return true
            },
            { message: 'Ranges must be in ascending order and not overlap.' },
          ),
      ),
    is_selected: z.boolean().default(false),
  })
  .superRefine((data, ctx) => {
    const { max_instance_number, instance_number_ranges } = data
    instance_number_ranges.forEach((range) => {
      const { end } = range

      if (end > max_instance_number) {
        ctx.addIssue({
          path: ['instance_number_ranges'],
          code: z.ZodIssueCode.custom,
          message: `Number exceeded`,
        })
      }
    })
  })

export const qcStudyDefinitionCreateSchema = z.object({
  study_description: z.string(),
  order: z.number().default(1),
  exam_metadata_ids: z.array(z.string()).min(1, 'Select source study description'),
  series_definitions: z.array(qcSeriesDefinitionCreateSchema),
})

export type QcStudyDefinitionCreateFormValues = z.infer<typeof qcStudyDefinitionCreateSchema>
