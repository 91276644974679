import { useQuery } from '@tanstack/react-query'
import { listCompaniesUsersCompaniesUsersGet } from '@common/config/api/client'
import { UserListParams } from '../types'
import queryKeyFactory from '../queryKeyFactory'

interface Options {
  enabled?: boolean
}

export default function useUsersList(params: UserListParams, options: Options = {}) {
  return useQuery({
    queryKey: queryKeyFactory.users(params),
    queryFn: async () => {
      const response = await listCompaniesUsersCompaniesUsersGet({
        query: {
          company_type: params.company_type,
          search: params.search,
          limit: params.limit,
          next_cursor: params.next_cursor,
          previous_cursor: params.previous_cursor,
          order_by_field: params.order_by_field,
          asc_order: params.asc_order,
        },
        throwOnError: true,
      })
      return response.data
    },
    enabled: options.enabled ?? true,
  })
}
