import BaseDialog from '@common/components/BaseDialog'
import { TextField } from '@common/components/Form/TextField'
import { zodResolver } from '@hookform/resolvers/zod'
import { Stack, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { SiteCreateSchema } from '@features/study_details/sites/schemas/sitesValidationSchema'
import RadioGroup from '@common/components/Form/RadioGroup'
import { SPACING } from '@common/theme/spacing'
import { Dropdown } from '@common/components/Form/Dropdown'
import { useGetStudySitesMetadata } from '../hooks/useStudySitesQueries'
import { useParams } from 'react-router-dom'
import { SiteAssociationTypeEnum } from '../types'

type UpdateSiteFormValues = {
  pseudo_id: string
  name: string
  contact_email: string
  contact_phone: string | null
  site_association_type: SiteAssociationTypeEnum
  modalities: Array<string>
  site_associations: Array<string>
}

export interface UpdateSiteDialogProps {
  onSave: (data: UpdateSiteFormValues) => void
  onClose: () => void
  data?: UpdateSiteFormValues | null
  title: string
}

const SITE_ASSOCIATION_TYPE = [
  {
    label: 'Primary',
    value: SiteAssociationTypeEnum.PRIMARY,
  },
  {
    label: 'Secondary',
    value: SiteAssociationTypeEnum.SECONDARY,
  },
]

const UpdateSiteDialog = ({ onSave, onClose, data }: UpdateSiteDialogProps) => {
  const form = useForm<UpdateSiteFormValues>({
    resolver: zodResolver(SiteCreateSchema),
    mode: 'onBlur',
    defaultValues: {
      pseudo_id: data?.pseudo_id,
      name: data?.name || '',
      contact_email: data?.contact_email || '',
      contact_phone: data?.contact_phone || '',
      site_association_type: data?.site_association_type ?? SiteAssociationTypeEnum.PRIMARY,
      site_associations: data?.site_associations ?? [],
      modalities: data?.modalities ?? [],
    },
  })
  const { studyId } = useParams<{ studyId: string }>()
  const { data: studySitesMetadata, isLoading } = useGetStudySitesMetadata(studyId!)

  const siteAssociationType = form.watch('site_association_type')

  const getOppositeAssociationTypeData = (type: SiteAssociationTypeEnum) => {
    return type === SiteAssociationTypeEnum.PRIMARY
      ? {
          value: SiteAssociationTypeEnum.SECONDARY,
          label: 'Secondary',
        }
      : {
          value: SiteAssociationTypeEnum.PRIMARY,
          label: 'Primary',
        }
  }

  const getAvailableSiteAssociations = () => {
    if (!studySitesMetadata?.site_associations) return []
    const oppositeType = getOppositeAssociationTypeData(siteAssociationType as SiteAssociationTypeEnum).value
    return studySitesMetadata.site_associations[oppositeType] || []
  }

  const handleFormSubmit = async () => {
    const formData = form.getValues()

    const isValid = await form.trigger()
    if (isValid) {
      onSave(formData)
      form.reset()
      onClose()
    }
  }

  return (
    <BaseDialog
      title="New site"
      description="Please provide the details for this study site below"
      open={true}
      onClose={onClose}
      onSubmit={handleFormSubmit}
      form={form}
      width={600}
      submitLabel="Edit site"
      cancelLabel="Cancel"
    >
      <Stack spacing={4}>
        <Stack spacing={3}>
          <Typography variant="body1" color="text.primary">
            1. General information
          </Typography>
          <TextField name="pseudo_id" control={form.control} label="Site ID" />
          <Stack direction="row" gap={SPACING.formFieldsSpacing}>
            <TextField name="name" control={form.control} label="Name" />
            <TextField name="contact_phone" control={form.control} label="Phone number" />
          </Stack>
          <TextField name="contact_email" control={form.control} label="Email address" />
          <Typography variant="body1" color="text.primary">
            2. Company information
          </Typography>
          <Dropdown
            name="modalities"
            control={form.control}
            options={studySitesMetadata?.modalities ?? []}
            loading={isLoading}
            label="Modalities"
            multiple
          />
          <Typography variant="body1" color="text.primary">
            3. Site relationships
          </Typography>
          <Stack direction="row" gap={3} alignItems="center">
            <Typography>Is this a primary or secondary site?</Typography>
            <RadioGroup name="site_association_type" control={form.control} options={SITE_ASSOCIATION_TYPE} row />
          </Stack>
          <Dropdown
            name="site_associations"
            control={form.control}
            options={getAvailableSiteAssociations()}
            label={`Select ${getOppositeAssociationTypeData(siteAssociationType as SiteAssociationTypeEnum).label} site associations`}
            multiple
          />
        </Stack>
      </Stack>
    </BaseDialog>
  )
}

export default UpdateSiteDialog
