import { DeleteOutlineRounded, ExpandLessRounded, KeyboardArrowDownRounded } from '@mui/icons-material'
import { Collapse, Divider, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

export default function ExamReason({ reason, comment }: { reason: string; comment: string }) {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  return (
    <Paper
      sx={{
        paddingX: 2,
        paddingY: 1,
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2">Site has not performed this examination</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Divider orientation="vertical" flexItem />
            <IconButton>
              <DeleteOutlineRounded
                sx={{
                  width: 20,
                  height: 20,
                  color: 'text.secondary',
                }}
              />
            </IconButton>
          </Stack>
        </Stack>
        <Paper
          sx={{
            paddingX: 2,
            paddingY: 0.25,
            borderRadius: 4,
            bgcolor: 'paper.main',
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle2">Hide reason and comment</Typography>
              <IconButton onClick={() => setOpen(!open)}>
                {open ? (
                  <ExpandLessRounded
                    sx={{
                      width: 24,
                      height: 24,
                      color: 'text.secondary',
                    }}
                  />
                ) : (
                  <KeyboardArrowDownRounded
                    sx={{
                      width: 24,
                      height: 24,
                      color: 'text.secondary',
                    }}
                  />
                )}
              </IconButton>
            </Stack>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Stack spacing={1}>
                <Stack spacing={0.25}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                    }}
                    variant="subtitle2"
                  >
                    Reason
                  </Typography>
                  <Typography variant="body2">{reason}</Typography>
                </Stack>
                <Stack spacing={0.25}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                    }}
                    variant="subtitle2"
                  >
                    Comment
                  </Typography>
                  <Typography variant="body2">{comment}</Typography>
                </Stack>
              </Stack>
            </Collapse>
          </Stack>
        </Paper>
      </Stack>
    </Paper>
  )
}
