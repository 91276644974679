import {
  excludeQcTasksQcTasksExcludePatch,
  ExcludeQcTasksQcTasksExcludePatchResponse,
  Body_exclude_qc_tasks_qc_tasks_exclude_patch,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useExcludeTasksMutation = (): UseMutationResult<
  ExcludeQcTasksQcTasksExcludePatchResponse,
  AxiosError<HTTPValidationError>,
  Body_exclude_qc_tasks_qc_tasks_exclude_patch
> => {
  return useMutation<
    ExcludeQcTasksQcTasksExcludePatchResponse,
    AxiosError<HTTPValidationError>,
    Body_exclude_qc_tasks_qc_tasks_exclude_patch
  >({
    mutationFn: async (body) => {
      const response = await excludeQcTasksQcTasksExcludePatch({
        body: body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTasksList'] })
    },
  })
}
