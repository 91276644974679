import { Chip } from '@mui/material'

export default function SourceStudyDescriptionChip({ description }: { description: string }) {
  return (
    <Chip
      data-testid="description-chip"
      label={description}
      size="small"
      sx={{
        backgroundColor: 'main.secondary',
        color: 'text.primary',
        padding: 0.5,
        alignItems: 'center',
      }}
    ></Chip>
  )
}
