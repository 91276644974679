import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { SyntheticEvent } from 'react'

export default function StudyTabs({
  tabs,
  currentActiveTab,
  setCurrentActiveTab,
}: {
  tabs: string[]
  currentActiveTab: number
  setCurrentActiveTab: (newValue: number) => void
}) {
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setCurrentActiveTab(newValue)
  }

  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
      }}
      data-testid="study-tabs-container"
    >
      <Box
        sx={{
          bgcolor: 'action.hover',
          borderRadius: 3,
          padding: 0.5,
          typography: 'subtitle1',
        }}
        data-testid="study-tabs-box"
      >
        <Tabs
          sx={{
            minHeight: 37,
          }}
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          value={currentActiveTab}
          onChange={handleChange}
          aria-label="enhanced tabs"
          data-testid="study-tabs"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              sx={{
                minWidth: 'unset',
                minHeight: 36,
                maxHeight: 36,
                color: 'text.primary',
                typography: 'subtitle1',
                '&.Mui-selected': {
                  bgcolor: 'background.default',
                  color: 'text.primary',
                  boxShadow: 2,
                  borderRadius: 2,
                },
              }}
              label={tab}
              data-testid={`study-tab-${index}`}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  )
}
