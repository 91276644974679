import { Divider, Stack, Typography } from '@mui/material'
import ExamDetailPopupMenuList from './ExamDetailPopupMenuList'
import { QcSeriesDescription } from '@common/config/api/client'

export default function SeriesDefinitionInstance({ seriesDescription }: { seriesDescription: QcSeriesDescription }) {
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" paddingX={2} paddingY={1}>
      <Stack spacing={0.25}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Source series description
        </Typography>
        <Typography variant="body2">{seriesDescription.source_series_description.series_description}</Typography>
      </Stack>
      <Stack spacing={0.25}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          New series description
        </Typography>
        <Typography variant="body2">{seriesDescription.series_label_name}</Typography>
      </Stack>
      <Stack spacing={0.25}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Images
        </Typography>
        {seriesDescription.instance_number_ranges.map((instanceNumberRange) => (
          <Typography variant="body2">
            {instanceNumberRange.start}-{instanceNumberRange.end}
          </Typography>
        ))}
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Divider orientation="vertical" flexItem />
        <ExamDetailPopupMenuList />
      </Stack>
    </Stack>
  )
}
