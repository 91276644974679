import { useCombinedPermissions } from '@auth/hooks/useCombinedPermissions'
import {
  Permission,
  QCTaskPermission,
  QueryPermission,
  ReadTaskPermission,
  SitePermission,
  StudyUserManagementPermission,
  SubjectPermission,
} from '@auth/permissionsEnum'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import { useParams } from 'react-router-dom'
import { RouteConfig, ROUTES } from 'src/routes'

type TabItem = {
  route: RouteConfig
  permissions?: Permission[]
}

const tabItems: TabItem[] = [
  {
    route: ROUTES.STUDY_DETAILS,
  },
  {
    route: ROUTES.SITES,
    permissions: [SitePermission.ALL],
  },
  {
    route: ROUTES.SUBJECTS,
    permissions: [SubjectPermission.ALL],
  },
  {
    route: ROUTES.READER_TASKS,
    permissions: [
      ReadTaskPermission.VIEW_ASSIGNED_TASKS,
      ReadTaskPermission.VIEW_COMPLETED_TASKS,
      ReadTaskPermission.VIEW_SKIPPED_TASKS,
      ReadTaskPermission.VIEW_TASK_POOL,
    ],
  },
  {
    route: ROUTES.QC_TASKS,
    permissions: [
      QCTaskPermission.VIEW_ASSIGNED_TASKS,
      QCTaskPermission.VIEW_COMPLETED_TASKS,
      QCTaskPermission.VIEW_TASK_POOL,
      QCTaskPermission.MANAGE_TASKS,
    ],
  },
  {
    route: ROUTES.QUERIES,
    permissions: [QueryPermission.ALL],
  },
  {
    route: ROUTES.ACCOUNT_MANAGEMENT,
    permissions: [StudyUserManagementPermission.ALL],
  },
]

const StudyDetailsTabs = () => {
  const { studyId } = useParams<{ studyId: string }>()
  const { hasAnyPermission } = useCombinedPermissions()

  const filteredTabRoutes = tabItems
    .filter((item) => !item.permissions || hasAnyPermission(item.permissions))
    .map((item) => ({
      ...item.route,
      path: item.route.path.replace(':studyId', studyId!),
    }))
  return <EnhancedNavTabs routes={filteredTabRoutes} />
}

export default StudyDetailsTabs
